import HeaderManagement from "component/headerManagement";
import { Button, Form, Image, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import { sorterNumber, sorterString } from "helper/sorter";
import TableManagement from "component/tableManagement";
import { useEffect, useState } from "react";
import DrawerUser from "common/drawer";
import { DisplayDrawer } from "./displayDrawer";
import { EditOutlined } from "@ant-design/icons";
import { useRandomRewardManagement } from "hooks/management/useRewardRandomManagement";
import { useReward } from "hooks/rewardRandom/useRewardRandom";
import { ITFDataTableRandomReward } from "types/rewardRandom.types";
import ModalRandomPoint from "./ModalSetRandomPoint";
import { PAGE_KEY } from "constant";
import ModalError from "common/modal/modalError";
import { useCommon, useCommonSuccess } from "hooks/common/useCommon";
import ModalSuccess from "common/modal/modalSuccess";
import ModalConfirmation from "common/modal/modalConfirmation";
import { useSelector } from "react-redux";
import { commonSelector } from "store/slice/common";
import { SearchTable } from "component/searchTable";

const { Text } = Typography;

const RandomProductPage = () => {
  const { t } = useTranslation();
  const [formRewardRandom] = Form.useForm();
  const isLoading = useSelector(commonSelector).loading;
  const [showModalConfirmation, setShowModalConfirmation] =
    useState<boolean>(false);

  const {
    title,
    onOpenDrawer,
    onCloseDrawer,
    isOperDrawer,
    dataEdit,
    handleSetFieldsEditUser,
    onClickEdit,
  } = useRandomRewardManagement(formRewardRandom);
  const {
    getDataProductRandom,
    dataTableRandomReward,
    onFinishProductRandomManagement,
    rowSelection,
    onClickDelete,
    selectedDeleteId,
    getDataRandomPoint,
    randomPoint,
  } = useReward(() => onCloseDrawer());

  const [isShowModalEditPoint, setIsShowModalEditPoint] =
    useState<boolean>(false);
  const [allPercent, setAllPercent] = useState<number>(0);
  const { isShowError, errorDetail, hideError } = useCommon(
    PAGE_KEY.ADMIN_ADD_PRODUCT_RANDOM
  );
  const { isShowSuccess, successDetail, hideSuccess } = useCommonSuccess(
    PAGE_KEY.ADMIN_ADD_PRODUCT_RANDOM
  );
  const { getColumnSearchProps } = SearchTable();

  const {
    isShowError: isShowErrorAdminEditPointRandom,
    errorDetail: errorDetailAdminEditPointRandom,
    hideError: hideErrorAdminEditPointRandom,
  } = useCommon(PAGE_KEY.ADMIN_EDIT_POINT_PRODUCT_RANDOM);

  const {
    isShowSuccess: isShowSuccessAdminEditPointRandom,
    successDetail: successDetailAdminEditPointRandom,
    hideSuccess: hideSuccessAdminEditPointRandom,
  } = useCommonSuccess(PAGE_KEY.ADMIN_EDIT_POINT_PRODUCT_RANDOM);

  const columns: ColumnsType<ITFDataTableRandomReward> = [
    {
      title: `${t("Reward Name")}`,
      key: "title",
      dataIndex: "title",
      ...getColumnSearchProps("title"),
      sorter: (a: ITFDataTableRandomReward, b: ITFDataTableRandomReward) =>
        sorterString(a.title, b.title),
      width: 200,
      ellipsis: true,
    },
    {
      title: t("Percent"),
      key: "percent",
      dataIndex: "percent",
      ...getColumnSearchProps("percent"),
      sorter: (a: ITFDataTableRandomReward, b: ITFDataTableRandomReward) =>
        sorterNumber(a.percent, b.percent),
      width: 150,
      render: (text: string) => {
        return <div>{text}%</div>;
      },
    },

    {
      title: t("Image"),
      key: "image",
      width: 150,
      render: (record: ITFDataTableRandomReward) => {
        return (
          <div>
            <Image
              width={200}
              src={`data:image/jpeg;base64,${record.imageBase64}`}
            />
          </div>
        );
      },
    },
    {
      title: t("Created By"),
      key: "createBy",
      dataIndex: "createBy",
      ...getColumnSearchProps("createBy"),
      sorter: (a: ITFDataTableRandomReward, b: ITFDataTableRandomReward) =>
        sorterString(a.createBy, b.createBy),
      width: 150,
    },
    {
      title: t("Action"),
      key: "action",
      align: "center",
      render: (record: ITFDataTableRandomReward) => {
        return (
          <div>
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => onClickEdit(record)}
            />
          </div>
        );
      },
      width: 100,
      fixed: "right",
    },
  ];

  useEffect(() => {
    getDataProductRandom();
    getDataRandomPoint();
  }, [getDataProductRandom, getDataRandomPoint]);

  const showTooltipAdd = () => {
    const tooltipAllPercent =
      allPercent >= 100 ? `${t("The percentage is 100% complete.")}` : "";
    const tooltipDataRandomReward =
      dataTableRandomReward.length >= 10
        ? `${t("Product Random is full.")}`
        : "";
    return tooltipAllPercent + " " + tooltipDataRandomReward;
  };

  return (
    <>
      <HeaderManagement
        title={title}
        onClickButtonAddNew={onOpenDrawer}
        hiddenDelete={false}
        onClickDelete={() => setShowModalConfirmation(true)}
        disabledDelete={selectedDeleteId.length === 0}
        disabledAdd={dataTableRandomReward.length >= 10 || allPercent >= 100}
        wordTooltip={showTooltipAdd()}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Button type="primary" onClick={() => setIsShowModalEditPoint(true)}>
          {t("Edit Point Product Random")}
        </Button>
      </div>
      <TableManagement
        columns={columns}
        data={dataTableRandomReward}
        rowSelection={rowSelection}
        key={
          dataTableRandomReward?.map((item) => item.productId).join(",") ||
          "tableRandomProduct"
        }
        summryTable={(pageData) => {
          let totalPercent = 0;

          pageData.forEach(({ percent }) => {
            totalPercent += percent;
          });
          setAllPercent(totalPercent);
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{t("Total")}</Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={2}>
                  <Text style={{ alignSelf: "center" }}>{totalPercent}%</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <DrawerUser
        onClose={onCloseDrawer}
        isOpen={isOperDrawer}
        title={title}
        dataEdit={dataEdit}
        form={formRewardRandom}
        handleSetFields={handleSetFieldsEditUser}
        displayContent={
          <DisplayDrawer
            form={formRewardRandom}
            dataEdit={dataEdit}
            IsOpen={isOperDrawer}
            allPercent={allPercent}
          ></DisplayDrawer>
        }
        onFinish={onFinishProductRandomManagement}
      />

      <ModalRandomPoint
        open={isShowModalEditPoint}
        onCancel={() => {
          setIsShowModalEditPoint(false);
          getDataRandomPoint();
        }}
        randomPoint={randomPoint}
      ></ModalRandomPoint>

      <ModalError
        isOpen={isShowError}
        onCancel={() => hideError()}
        code={errorDetail.code}
        title={errorDetail.title}
        message={errorDetail.message}
      ></ModalError>

      <ModalSuccess
        isOpen={isShowSuccess}
        onCancel={() => hideSuccess()}
        title={successDetail.title}
        message={successDetail.message}
      ></ModalSuccess>

      <ModalConfirmation
        isOpen={showModalConfirmation}
        onCancel={() => setShowModalConfirmation(false)}
        onOK={() => {
          onClickDelete();
          setShowModalConfirmation(false);
        }}
        title={t("Do you want to delete ?")}
        loading={isLoading}
        type="delete"
      ></ModalConfirmation>

      <ModalError
        isOpen={isShowErrorAdminEditPointRandom}
        onCancel={() => hideErrorAdminEditPointRandom()}
        code={errorDetailAdminEditPointRandom.code}
        title={errorDetailAdminEditPointRandom.title}
        message={errorDetailAdminEditPointRandom.message}
      ></ModalError>

      <ModalSuccess
        isOpen={isShowSuccessAdminEditPointRandom}
        onCancel={() => {
          hideSuccessAdminEditPointRandom();
          setIsShowModalEditPoint(false);
        }}
        title={successDetailAdminEditPointRandom.title}
        message={successDetailAdminEditPointRandom.message}
      ></ModalSuccess>
    </>
  );
};

export default RandomProductPage;
