/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Drawer, Form, FormInstance, Row } from "antd";
import { ITFDataTableUser } from "@/types/userManagement.types";
import { useTranslation } from "react-i18next";
import { ITFDataTableAdmin } from "types/adminManagement.types";
import { ITFDataTableSuperAdmin } from "types/superAdminManagement.types";
import { ITFDataTableRandomReward } from "types/rewardRandom.types";
import { ITFResponseRewardClaim } from "types/rewardClaim.types";
import { ITFDataTableRewardCheckIn } from "types/rewardCheckIn.type";

interface ITFPropsDrawerUser {
  onClose: (e: React.MouseEvent | React.KeyboardEvent) => void;
  isOpen: boolean;
  title: string;
  dataEdit:
    | ITFDataTableUser
    | ITFDataTableAdmin
    | ITFDataTableSuperAdmin
    | ITFDataTableRandomReward
    | ITFResponseRewardClaim
    | ITFDataTableRewardCheckIn
    | undefined;
  form: FormInstance;
  handleSetFields: Function;
  displayContent: JSX.Element;
  onFinish: (values: any, dataEdit: any) => void;
  onFinishFailed?: ((values: any) => void) | undefined;
  width?: number;
}

const DrawerCommon = (props: ITFPropsDrawerUser) => {
  const { t } = useTranslation();
  const {
    isOpen,
    onClose,
    title,
    dataEdit,
    form,
    handleSetFields,
    displayContent,
    onFinish,
    onFinishFailed,
    width = 500,
  } = props;

  useEffect(() => {
    if (dataEdit === undefined) {
      if (title === "สิ่งของในวงล้อ") {
        form.resetFields();
        form.setFieldsValue({
          fontColor: "#FFFFFF",
          backgroundColor: "#FFFFFF",
        });
      } else {
        form.resetFields();
      }
    } else {
      handleSetFields();
    }
  }, [dataEdit, form, title, isOpen]);

  return (
    <Drawer
      title={`${dataEdit === undefined ? t("Add") : t("Edit")} ${title}`}
      placement="right"
      size={"default"}
      onClose={onClose}
      open={isOpen}
      maskClosable={false}
      forceRender
      width={width}
    >
      <Form
        layout="vertical"
        onFinish={(value: any) => onFinish(value, dataEdit)}
        onFinishFailed={onFinishFailed}
        form={form}
        scrollToFirstError
      >
        {displayContent}
        <Row style={{ justifyContent: "flex-end", gap: "8px" }}>
          <Button onClick={onClose} danger>
            {t("Cancel")}
          </Button>

          <Button type="primary" htmlType="submit">
            {t("Submit")}
          </Button>
        </Row>
      </Form>
    </Drawer>
  );
};

export default DrawerCommon;
