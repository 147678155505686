import {
  ITFDataStatusHistory,
  ITFDataTableHistory,
} from "types/historyManagement.types";
import {
  getAllUserRandomProduct,
  getCheckInHistory,
  getStatusDescr,
  getUserProductReward,
} from "api/history/history";
import { showFormatDate } from "helper/formatter";
import { useState } from "react";
import { setLoading } from "store/slice/common";
import { useAppDispatch } from "store/store";
import {
  setDataTableHistoryCheckInReward,
  setDataTableHistoryClaimInReward,
  setDataTableHistoryRandomInReward,
} from "store/slice/history";

export const useHistory = (dataCheckInType: any, userId?: string) => {
  const dispatch = useAppDispatch();

  const [dataStatusHistory, setDataStatusHistory] = useState<
    ITFDataStatusHistory[]
  >([]);

  const getDataHistoryCheckInReward = async (
    createDate?: string,
    endDate?: string,
    searchStatus?: string
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await getCheckInHistory(
        userId,
        createDate || "",
        endDate || ""
      );
      let searchData = [];
      if (searchStatus) {
        searchData = response?.data.filter((item: any) => {
          return item.status === searchStatus;
        });
      } else {
        searchData = response?.data;
      }

      const dataHistoryCheckInReward = searchData.map(
        (item: any, count: number): ITFDataTableHistory => {
          let nameReward = "";
          if (item.checkinType === "CI01") {
            const filtered = dataCheckInType.filter((itemInFilter: any) => {
              return itemInFilter.gdCode === item.checkinType;
            });
            nameReward = item.reward + " " + filtered[0].descrLocal;
          } else if (item.checkinType === "CI02") {
            nameReward = item.title;
          } else if (item.checkinType === "CI03") {
            const filtered = dataCheckInType.filter((itemInFilter: any) => {
              return itemInFilter.gdCode === item.checkinType;
            });
            nameReward = filtered[0].descrLocal;
          }

          const filteredStatus = dataStatusHistory.filter(
            (itemInFilter: ITFDataStatusHistory) => {
              return itemInFilter.gdCode === item.status;
            }
          );

          return {
            key: `${count}`,
            userId: item.userId,
            rewardName: nameReward,
            rewardImg: item.imageBase64,
            updateDate: showFormatDate(item.createDate),
            updateBy: item.updateBy,
            status: item.status,
            checkinType: item.checkinType,
            response: item,
            id: "dataHistoryCheckInReward",
            statusDescrLocal: filteredStatus[0].descrLocal,
            createDate: showFormatDate(item.createDate),
          };
        }
      );
      dispatch(setDataTableHistoryCheckInReward(dataHistoryCheckInReward));
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getDataHistoryRandomReward = async (
    createDate?: string,
    endDate?: string,
    searchStatus?: string
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await getAllUserRandomProduct(
        userId,
        createDate || "",
        endDate || ""
      );
      let searchData = [];
      if (searchStatus) {
        searchData = response?.data.filter((item: any) => {
          return item.status === searchStatus;
        });
      } else {
        searchData = response?.data;
      }
      const dataHistoryRandomReward = searchData.map(
        (item: any, count: number): ITFDataTableHistory => {
          const filteredStatus = dataStatusHistory.filter(
            (itemInFilter: ITFDataStatusHistory) => {
              return itemInFilter.gdCode === item.status;
            }
          );

          return {
            key: `${count}`,
            userId: item.userId,
            rewardName: item.title,
            rewardImg: item.imageBase64,
            updateDate: showFormatDate(item.randomDate),
            updateBy: item.updateBy,
            status: item.status,
            checkinType: "empty",
            response: item,
            id: "dataHistoryRandomReward",
            statusDescrLocal: filteredStatus[0].descrLocal,
            createDate: showFormatDate(item.randomDate),
          };
        }
      );
      dispatch(setDataTableHistoryRandomInReward(dataHistoryRandomReward));
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getDataHistoryClaimReward = async (
    createDate?: string,
    endDate?: string,
    searchStatus?: string
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await getUserProductReward(
        userId,
        createDate || "",
        endDate || ""
      );

      let searchData = [];
      if (searchStatus) {
        searchData = response?.data.filter((item: any) => {
          return item.status === searchStatus;
        });
      } else {
        searchData = response?.data;
      }

      const dataHistoryClaimReward = searchData.map(
        (item: any, count: number): ITFDataTableHistory => {
          const filteredStatus = dataStatusHistory.filter(
            (itemInFilter: ITFDataStatusHistory) => {
              return itemInFilter.gdCode === item.status;
            }
          );
          return {
            key: `${count}`,
            userId: item.userId,
            rewardName: item.title,
            rewardImg: item.imageBase64,
            updateDate: showFormatDate(item.claimDate),
            updateBy: item.updateBy,
            status: item.status,
            checkinType: "empty",
            response: item,
            id: "dataHistoryClaimReward",
            statusDescrLocal: filteredStatus[0].descrLocal,
            createDate: showFormatDate(item.claimDate),
          };
        }
      );
      dispatch(setDataTableHistoryClaimInReward(dataHistoryClaimReward));
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getDataStatusDescrHistory = async () => {
    dispatch(setLoading(true));
    try {
      const response = await getStatusDescr();
      setDataStatusHistory(response.data);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  return {
    getDataHistoryCheckInReward,
    getDataHistoryRandomReward,
    getDataHistoryClaimReward,
    getDataStatusDescrHistory,
    dataStatusHistory,
  };
};
