import colorConstants from "constant/colorConstants";
import { Avatar, Button, Col, Popover, Row, Typography } from "antd";
import { showFormatNumber } from "helper/formatter";
import { logout } from "api/common/authentication";
import { commonSelector, setShowModalCheckIn } from "store/slice/common";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import ModalViewProfile from "../../../viewProfile/modalViewProfile";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import ModalLogin from "../../../login/modalLogin";
import { useAppDispatch } from "store/store";
import ModalHistoryReward from "pages/customer/history/modalHistoryReward";
import { isShowFeatureCheckIn } from "constant/common";

const { Text } = Typography;

const ShowUserInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const [isSharePopoverOpen, setIsSharePopoverOpen] = useState(false);
  const userData = useSelector(commonSelector).dataUser;
  const [isShowModalLoginCustomer, setIsShowModalLoginCustomer] =
    useState<boolean>(false);
  const [isShowModalViewProfile, setIsShowModalViewProfile] =
    useState<boolean>(false);
  const [isShowModalHistory, setIsShowModalHistory] = useState<boolean>(false);

  const handlePopoverVisibleChange = () => {
    setIsSharePopoverOpen(!isSharePopoverOpen);
  };

  const showModalViewProfile = () => {
    setIsShowModalViewProfile(true);
    setIsSharePopoverOpen(false);
  };

  const onClickDailyCheckIn = () => {
    dispatch(setShowModalCheckIn(true));
    setIsSharePopoverOpen(false);
  };

  const onClicHistory = () => {
    setIsShowModalHistory(true);
    setIsSharePopoverOpen(false);
  };

  const fullname = userData?.userId;

  const content = (
    <div
      style={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      <div>
        <Text
          style={{ cursor: "pointer" }}
          underline
          onClick={() => showModalViewProfile()}
        >
          {t("View Profile")}
        </Text>
      </div>
      {isShowFeatureCheckIn && (
        <Text
          style={{ cursor: "pointer" }}
          underline
          onClick={() => onClickDailyCheckIn()}
        >
          {t("Daily check In")}
        </Text>
      )}

      <Text
        style={{ cursor: "pointer" }}
        underline
        onClick={() => onClicHistory()}
      >
        {t("Receiving History")}
      </Text>
      <Button danger style={{ marginTop: "10px" }} onClick={() => logout()}>
        {t("Log Out")}
      </Button>
    </div>
  );

  const showAvatarLogin = () => {
    if (isEmpty(cookies.accessToken)) {
      return (
        <>
          <Avatar
            className="iconLogin"
            style={{
              backgroundColor: "grey",
              verticalAlign: "middle",
              cursor: "pointer",
            }}
            size="large"
            onClick={() => setIsShowModalLoginCustomer(true)}
            icon={<UserOutlined />}
          ></Avatar>
          <ModalLogin
            isOpen={isShowModalLoginCustomer}
            onClose={() => setIsShowModalLoginCustomer(false)}
          />
        </>
      );
    }

    return (
      <Row style={{ alignItems: "center" }}>
        <Col
          style={{
            color: colorConstants.BaseGray,
            marginRight: "8px",
          }}
        >
          <div className="fullName">{fullname}</div>
          <Row style={{ gap: "8px", justifyContent: "flex-end" }}>
            {showFormatNumber(userData?.totalPoint)} {t("Point(s)")}
          </Row>
        </Col>
        <Col>
          <Popover
            placement="bottomRight"
            content={content}
            trigger="click"
            open={isSharePopoverOpen}
            onOpenChange={handlePopoverVisibleChange}
          >
            <Avatar
              style={{
                backgroundColor: colorConstants.Primary500,
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              size="large"
              gap={4}
              icon={<UserOutlined />}
            ></Avatar>
          </Popover>
        </Col>

        <ModalViewProfile
          isOpen={isShowModalViewProfile}
          onClose={() => setIsShowModalViewProfile(false)}
        />

        <ModalHistoryReward
          isOpen={isShowModalHistory}
          onClose={() => setIsShowModalHistory(false)}
        ></ModalHistoryReward>
      </Row>
    );
  };

  return showAvatarLogin();
};

export default ShowUserInfo;
