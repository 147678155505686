import dayjs from "dayjs";

export const showFormatNumber = (data: any) => {
  if (Number(data)) {
    return data?.toFixed(2);
  }
  return "0.00";
};

export const showFormatDate = (dateInput: Date) => {
  return dayjs(new Date(dateInput)).format("DD/MM/YYYY H:mm");
};

export const parser = (value: any) => {
  if (!value) {
    return 0;
  }

  if (typeof value == "number") {
    return value;
  }

  if (typeof value == "string") {
    const checkComma = value.includes(",");
    if (checkComma) {
      value = value.replaceAll(",", "");
      return value;
    }
  }

  const floated = parseFloat(value);
  if (isNaN(floated)) {
    return 0;
  }

  return `${parseFloat(value)}`;
};
