import { httpClient } from "utils/HttpClient";

export const getAllUserRandomProduct = async (
  userId: string | undefined,
  startDate: string,
  endDate: string
) => {
  return await httpClient.get("/GetAllUserRandomProduct", {
    params: { userId, startDate, endDate },
  });
};

export const getCheckInHistory = async (
  userId: string | undefined,
  startDate: string,
  endDate: string
) => {
  return await httpClient.get("/GetCheckInHistory", {
    params: { userId, startDate, endDate },
  });
};

export const getUserProductReward = async (
  userId: string | undefined,
  startDate: string,
  endDate: string
) => {
  return await httpClient.get("/GetUserProductReward", {
    params: { userId, startDate, endDate },
  });
};

export const getStatusDescr = async () => {
  return await httpClient.get("/GetStatusDescr");
};

export const updateUserProductRandom = async (
  id: string,
  status: string,
  extNumber: number,
  userId: string
) => {
  return await httpClient.put(
    `/UpdateUserProductRandom?userId=${userId}&status=${status}&extNumber=${extNumber}&productId=${id}`
  );
};

export const updateStatusUserProductReward = async (
  id: string,
  status: string,
  extNumber: number,
  userId: string
) => {
  return await httpClient.put(
    `/updateStatusUserProductReward?userId=${userId}&status=${status}&extNumber=${extNumber}&productId=${id}`
  );
};

export const updateStatueCheckinHistory = async (
  id: string,
  status: string,
  userId: string
) => {
  return await httpClient.put(
    `/UpdateStatueCheckinHistory?userId=${userId}&status=${status}&serialNo=${id}`
  );
};
