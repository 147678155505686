import HeaderManagement from "component/headerManagement";
import { Form, Image } from "antd";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import { sorterNumber, sorterString } from "helper/sorter";
import TableManagement from "component/tableManagement";
import { useEffect, useState } from "react";
import DrawerUser from "common/drawer";
import { DisplayDrawer } from "./displayDrawer";
import { EditOutlined } from "@ant-design/icons";
import { useRewardClaim } from "hooks/rewardClaim/useRewardClaim";
import { ITFResponseRewardClaim } from "types/rewardClaim.types";
import { useRewardClaimManagement } from "hooks/management/useRewardClaimManagement";
import { PAGE_KEY } from "constant";
import ModalError from "common/modal/modalError";
import { useCommon, useCommonSuccess } from "hooks/common/useCommon";
import ModalSuccess from "common/modal/modalSuccess";
import ModalConfirmation from "common/modal/modalConfirmation";
import { useSelector } from "react-redux";
import { commonSelector } from "store/slice/common";
import { SearchTable } from "component/searchTable";

const RewardManagementPage = () => {
  const { t } = useTranslation();
  const { getColumnSearchProps } = SearchTable();
  const [formRewardClaimManagement] = Form.useForm();
  const isLoading = useSelector(commonSelector).loading;
  const [showModalConfirmation, setShowModalConfirmation] =
    useState<boolean>(false);

  const {
    title,
    onOpenDrawer,
    onCloseDrawer,
    isOperDrawer,
    dataEdit,
    handleSetFieldsEdit,
    onClickEdit,
  } = useRewardClaimManagement(formRewardClaimManagement);
  const {
    dataRewardClaim,
    onFinishRewardClaim,
    getDataRewardClaim,
    rowSelection,
    selectedDeleteId,
    onClickDelete,
  } = useRewardClaim(() => onCloseDrawer());

  const { isShowError, errorDetail, hideError } = useCommon(
    PAGE_KEY.ADMIN_ADD_PRODUCT
  );

  const { isShowSuccess, successDetail, hideSuccess } = useCommonSuccess(
    PAGE_KEY.ADMIN_ADD_PRODUCT
  );

  const columns: ColumnsType<ITFResponseRewardClaim> = [
    {
      title: `${t("Title Product Claim Reward")}`,
      key: "title",
      dataIndex: "title",
      ...getColumnSearchProps("title"),
      sorter: (a: ITFResponseRewardClaim, b: ITFResponseRewardClaim) =>
        sorterString(a.title, b.title),
      width: 200,
      ellipsis: true,
    },
    {
      title: t("Description"),
      key: "description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      sorter: (a: ITFResponseRewardClaim, b: ITFResponseRewardClaim) =>
        sorterString(a.description, b.description),
      width: 150,
    },
    {
      title: t("Image"),
      key: "image",
      width: 150,
      render: (record: ITFResponseRewardClaim) => {
        return (
          <div>
            <Image
              width={200}
              src={`data:image/jpeg;base64,${record.imageBase64}`}
            />
          </div>
        );
      },
    },
    {
      title: t("Point Claim Reward"),
      key: "point",
      dataIndex: "point",
      align: "right",
      ...getColumnSearchProps("point"),
      sorter: (a: ITFResponseRewardClaim, b: ITFResponseRewardClaim) =>
        sorterNumber(a.point, b.point),
      width: 150,
    },
    // {
    //   title: t("ProductQTY Claim Reward"),
    //   key: "productQTY",
    //   dataIndex: "productQTY",
    //   align: "right",
    //   ...getColumnSearchProps("productQTY"),
    //   sorter: (a: ITFResponseRewardClaim, b: ITFResponseRewardClaim) =>
    //     sorterNumber(a.productQTY, b.productQTY),
    //   width: 150,
    // },
    {
      title: t("Action"),
      key: "action",
      align: "center",
      render: (record: ITFResponseRewardClaim) => {
        return (
          <div>
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => onClickEdit(record)}
            />
          </div>
        );
      },
      width: 100,
      fixed: "right",
    },
  ];

  useEffect(() => {
    getDataRewardClaim();
  }, [getDataRewardClaim]);

  return (
    <>
      <HeaderManagement
        title={title}
        onClickButtonAddNew={onOpenDrawer}
        disabledDelete={selectedDeleteId.length === 0}
        hiddenDelete={false}
        onClickDelete={() => setShowModalConfirmation(true)}
      />

      <TableManagement
        columns={columns}
        data={dataRewardClaim}
        rowSelection={rowSelection}
        key={dataRewardClaim?.map((item) => item.productId).join(",")}
      />

      <DrawerUser
        onClose={onCloseDrawer}
        isOpen={isOperDrawer}
        title={title}
        dataEdit={dataEdit}
        form={formRewardClaimManagement}
        handleSetFields={handleSetFieldsEdit}
        displayContent={
          <DisplayDrawer
            form={formRewardClaimManagement}
            dataEdit={dataEdit}
            isOpen={isOperDrawer}
          />
        }
        onFinish={onFinishRewardClaim}
      />

      <ModalError
        isOpen={isShowError}
        onCancel={() => hideError()}
        code={errorDetail.code}
        title={errorDetail.title}
        message={errorDetail.message}
      ></ModalError>

      <ModalSuccess
        isOpen={isShowSuccess}
        onCancel={() => hideSuccess()}
        title={successDetail.title}
        message={successDetail.message}
      ></ModalSuccess>

      <ModalConfirmation
        isOpen={showModalConfirmation}
        onCancel={() => setShowModalConfirmation(false)}
        onOK={() => {
          onClickDelete();
          setShowModalConfirmation(false);
        }}
        title={t("Do you want to delete ?")}
        loading={isLoading}
        type="delete"
      ></ModalConfirmation>
    </>
  );
};

export default RewardManagementPage;
