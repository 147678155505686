import DrawerUser from "common/drawer";
import TableManagement from "component/tableManagement";
import HeaderManagement from "component/headerManagement";
import { useUserManagement } from "hooks/management/useUserManagement";
import { EditOutlined } from "@ant-design/icons";
import { ITFDataTableUser } from "types/userManagement.types";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { sorterNumber, sorterString } from "helper/sorter";
import { useUser } from "hooks/user/useUser";
import { DisplayDrawerUser } from "./displayDrawer";
import { PAGE_KEY, TYPE_ROLE } from "constant";
import ModalError from "common/modal/modalError";
import { useCommon, useCommonSuccess } from "hooks/common/useCommon";
import ModalSuccess from "common/modal/modalSuccess";
import { showFormatNumber } from "helper/formatter";
import { isEmpty } from "lodash";
import type { ColumnsType } from "antd/es/table";
import { SearchTable } from "component/searchTable";

const UserPage = () => {
  const [formUserManagement] = Form.useForm();
  const { t } = useTranslation();
  const {
    title,
    isOperDrawer,
    onOpenDrawer,
    onCloseDrawer,
    dataEdit,
    handleSetFieldsEditUser,
    onFinishFailedUser,
    onClickEdit,
  } = useUserManagement(formUserManagement);
  const { getColumnSearchProps } = SearchTable();

  const { dataUserManagement, onFinishUser, getDataUser } = useUser(
    TYPE_ROLE.USER,
    () => onCloseDrawer()
  );
  const { isShowError, errorDetail, hideError } = useCommon(
    PAGE_KEY.ADMIN_ADD_USER
  );
  const { isShowSuccess, successDetail, hideSuccess } = useCommonSuccess(
    PAGE_KEY.ADMIN_ADD_USER
  );

  const columns: ColumnsType<ITFDataTableUser> = [
    {
      title: `${t("First name")} - ${t("Last name")}`,
      key: "name",
      ...getColumnSearchProps("fullName"),
      render: (record: ITFDataTableUser) => {
        if (isEmpty(record.fullName.replace(/ /g, ""))) {
          return "-";
        }
        return <div>{record.fullName}</div>;
      },
      sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
        sorterString(a.fullName, b.fullName),
      width: 200,
      ellipsis: true,
    },
    {
      title: t("Username"),
      key: "userId",
      dataIndex: "userId",
      sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
        sorterString(a.userId, b.userId),
      width: 150,
      ...getColumnSearchProps("userId"),
      render: (text: any) => {
        if (isEmpty(text)) {
          return "-";
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
        sorterString(a.address, b.address),
      ellipsis: true,
      width: 200,
      ...getColumnSearchProps("address"),
      render: (text: any) => {
        if (isEmpty(text)) {
          return "-";
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t("Line ID"),
      key: "lineId",
      dataIndex: "lineId",
      sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
        sorterString(a.lineId, b.lineId),
      width: 150,
      ...getColumnSearchProps("lineId"),
      render: (text: any) => {
        if (isEmpty(text)) {
          return "-";
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t("Phone Number"),
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
        sorterString(a.phoneNumber, b.phoneNumber),
      width: 150,
      ...getColumnSearchProps("phoneNumber"),
      render: (text: any) => {
        if (isEmpty(text)) {
          return "-";
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t("Phone Number Second"),
      key: "phoneNumberSecond",
      dataIndex: "phoneNumberSecond",
      sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
        sorterString(a.phoneNumberSecond, b.phoneNumberSecond),
      width: 150,
      ...getColumnSearchProps("phoneNumberSecond"),
      render: (text: any) => {
        if (isEmpty(text)) {
          return "-";
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t("Source"),
      key: "source",
      dataIndex: "source",
      sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
        sorterString(a.source, b.source),
      width: 150,
      ...getColumnSearchProps("source"),
      render: (text: any) => {
        if (isEmpty(text)) {
          return "-";
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t("Total Point"),
      key: "totalPoint",
      dataIndex: "totalPoint",
      sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
        sorterNumber(a.totalPoint, b.totalPoint),
      width: 100,
      ...getColumnSearchProps("totalPoint"),
      render: (text: ITFDataTableUser) => {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {showFormatNumber(text)}
          </div>
        );
      },
    },
    // {
    //   title: t("Total Deposit"),
    //   key: "totalDeposit",
    //   dataIndex: "totalDeposit",
    //   sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
    //     sorterNumber(a.totalDeposit, b.totalDeposit),
    //   width: 100,
    // },
    // {
    //   title: t("Total Bonus"),
    //   key: "totalBonus",
    //   dataIndex: "totalBonus",
    //   sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
    //     sorterNumber(a.totalBonus, b.totalBonus),
    //   width: 100,
    // },
    // {
    //   title: t("Total Withdraw"),
    //   key: "totalWithdraw",
    //   dataIndex: "totalWithdraw",
    //   sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
    //     sorterNumber(a.totalWithdraw, b.totalWithdraw),
    //   width: 100,
    // },
    // {
    //   title: t("Deposit Amount"),
    //   key: "depositAmount",
    //   dataIndex: "depositAmount",
    //   sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
    //     sorterNumber(a.depositAmount, b.depositAmount),
    //   width: 100,
    // },
    // {
    //   title: t("Bonus Amount"),
    //   key: "bonusAmount",
    //   dataIndex: "bonusAmount",
    //   sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
    //     sorterNumber(a.bonusAmount, b.bonusAmount),
    //   width: 100,
    // },
    // {
    //   title: t("Withdraw Amount"),
    //   key: "withDrawAmount",
    //   dataIndex: "withDrawAmount",
    //   sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
    //     sorterNumber(a.withDrawAmount, b.withDrawAmount),
    //   width: 100,
    // },
    {
      title: t("Status"),
      key: "valiStatus",
      dataIndex: "valiStatus",
      sorter: (a: ITFDataTableUser, b: ITFDataTableUser) =>
        sorterString(a.valiStatus, b.valiStatus),
      width: 150,
      ...getColumnSearchProps("valiStatus"),
      render: (text: string) => {
        return <div>{text === "A" ? t("Active") : t("Un Active")}</div>;
      },
    },
    {
      title: t("Action"),
      key: "action",
      align: "center",
      render: (record: ITFDataTableUser) => {
        return (
          <div>
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => onClickEdit(record)}
            />
          </div>
        );
      },
      width: 100,
      fixed: "right",
    },
  ];

  useEffect(() => {
    getDataUser();
  }, [getDataUser]);

  return (
    <>
      <HeaderManagement title={title} onClickButtonAddNew={onOpenDrawer} />

      <TableManagement columns={columns} data={dataUserManagement} />

      <DrawerUser
        onClose={onCloseDrawer}
        isOpen={isOperDrawer}
        title={title}
        dataEdit={dataEdit}
        form={formUserManagement}
        handleSetFields={handleSetFieldsEditUser}
        displayContent={
          <DisplayDrawerUser dataEdit={dataEdit}></DisplayDrawerUser>
        }
        onFinish={onFinishUser}
        onFinishFailed={onFinishFailedUser}
      />

      <ModalError
        isOpen={isShowError}
        onCancel={() => hideError()}
        code={errorDetail.code}
        title={errorDetail.title}
        message={errorDetail.message}
      ></ModalError>

      <ModalSuccess
        isOpen={isShowSuccess}
        onCancel={() => hideSuccess()}
        title={successDetail.title}
        message={successDetail.message}
      ></ModalSuccess>
    </>
  );
};

export default UserPage;
