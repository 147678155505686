import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ITFDataTableUser } from "types/userManagement.types";

export interface ErrorDetailType {
  code: string | null;
  title: string | null;
  message: string | null;
}

export interface commonStage {
  loadingLogin: boolean;
  dataUser: ITFDataTableUser | undefined;
  loading: boolean;
  errors: string[];
  errorDetail: ErrorDetailType;
  isShowModalCheckIn: boolean;
}

const initialValues: commonStage = {
  loadingLogin: false,
  dataUser: undefined,
  loading: false,
  errors: [],
  errorDetail: {
    code: "",
    title: "",
    message: "",
  },
  isShowModalCheckIn: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialValues,
  reducers: {
    setLoadingLogin: (state: commonStage, action: PayloadAction<boolean>) => {
      state.loadingLogin = action.payload;
    },
    setDataUser: (
      state: commonStage,
      action: PayloadAction<ITFDataTableUser | undefined>
    ) => {
      state.dataUser = action.payload;
    },
    setLoading: (state: commonStage, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addError: (state: commonStage, action: PayloadAction<string>) => {
      state.errors.push(action.payload);
    },
    removeError: (state: commonStage, action: PayloadAction<string>) => {
      state.errors = state.errors.filter(
        (loading: string) => action.payload !== loading
      );
    },
    setErrorCode: (state: commonStage, action: PayloadAction<string>) => {
      state.errorDetail.code = action.payload;
    },
    setErrorTitle: (state: commonStage, action: PayloadAction<string>) => {
      state.errorDetail.title = action.payload;
    },
    setErrorMessage: (state: commonStage, action: PayloadAction<string>) => {
      state.errorDetail.message = action.payload;
    },
    clearErrorDetail: (state: commonStage) => {
      state.errorDetail = initialValues.errorDetail;
    },
    setShowModalCheckIn: (
      state: commonStage,
      action: PayloadAction<boolean>
    ) => {
      state.isShowModalCheckIn = action.payload;
    },
  },
});

export const {
  setLoadingLogin,
  setDataUser,
  setLoading,
  addError,
  removeError,
  setErrorCode,
  setErrorTitle,
  setErrorMessage,
  clearErrorDetail,
  setShowModalCheckIn,
} = commonSlice.actions;
export const commonSelector = (store: RootState) => store.common;

export default commonSlice.reducer;
