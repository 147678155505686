import { ITFDataTableUser } from "types/userManagement.types";
import { useCallback, useState } from "react";
import { getUserInfo, putUserInfo } from "api/user/user";
import { postUserInfo } from "api/user/user";
import { useAppDispatch } from "store/store";
import { setLoading } from "store/slice/common";
import { PAGE_KEY } from "constant";
import { useCommon, useCommonSuccess } from "../common/useCommon";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { parser } from "helper/formatter";

export const useUser = (role: string, onCloseDrawer: Function) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [dataUserManagement, setDataUserManagement] = useState<
    ITFDataTableUser[]
  >([]);
  const { showError, setErrorTitle, setErrorMessage, setErrorCode } = useCommon(
    PAGE_KEY.ADMIN_ADD_USER
  );

  const { showSuccess, setSuccessTitle } = useCommonSuccess(
    PAGE_KEY.ADMIN_ADD_USER
  );

  const getDataUser = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const response = await getUserInfo(role);
      const data = response.data.map((item: any, count: number) => {
        return {
          ...item,
          key: count,
          fullName:
            (isEmpty(item.firstName) ? "" : item.firstName) +
            " " +
            (isEmpty(item.lastName) ? "" : item.lastName),
        };
      });
      setDataUserManagement(data);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, role]);

  const onFinishUser = async (value: ITFDataTableUser, dataEdit: any) => {
    const data = {
      ...value,
      Role: role,
      totalPoint: Number(parser(value.totalPoint)),
    };
    try {
      if (dataEdit !== undefined) {
        await putUserInfo(data);
      } else {
        await postUserInfo(data);
      }
      getDataUser();
      onCloseDrawer();

      setSuccessTitle(t("Add/Edit User Success"));
      showSuccess();
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitle(t("Add/Edit User Error"));
      setErrorCode(error.response.status);
      setErrorMessage(message);
      showError();
    } finally {
    }
  };

  return {
    dataUserManagement,
    getDataUser,
    onFinishUser,
  };
};
