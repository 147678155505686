/* eslint-disable react-hooks/exhaustive-deps */
import { sorterString } from "helper/sorter";
import { ITFDataTableSerialNumber } from "types/serialNumberManagement";
import { ColumnsType } from "antd/es/table";
import HeaderManagement from "component/headerManagement";
import TableManagement from "component/tableManagement";
import { useSerialNumberManagement } from "hooks/management/useSerialNumber";
import { useTranslation } from "react-i18next";
import { SearchTable } from "component/searchTable";
import { useEffect } from "react";

const SerialNumberManagent = () => {
  const { t } = useTranslation();

  const { title, getDataSerialNumberAllUser, dataSerialNumber } =
    useSerialNumberManagement();
  const { getColumnSearchProps } = SearchTable();

  const columns: ColumnsType<ITFDataTableSerialNumber> = [
    {
      title: t("Username"),
      key: "userId",
      dataIndex: "userId",
      sorter: (a: ITFDataTableSerialNumber, b: ITFDataTableSerialNumber) =>
        sorterString(a.userId, b.userId),
      width: 100,
      ...getColumnSearchProps("userId"),
    },
    {
      title: t("Serial Number"),
      key: "serialNo",
      align: "center",
      width: 100,
      dataIndex: "serialNo",
      sorter: (a: ITFDataTableSerialNumber, b: ITFDataTableSerialNumber) =>
        sorterString(a.serialNo, b.serialNo),
      ...getColumnSearchProps("serialNo"),
    },
    {
      title: t("Create Date"),
      key: "createDate",
      dataIndex: "createDate",
      ...getColumnSearchProps("createDate"),
      sorter: (a: ITFDataTableSerialNumber, b: ITFDataTableSerialNumber) =>
        sorterString(a.createDate, b.createDate),
      width: 150,
    },
    {
      title: t("Status"),
      key: "status",
      dataIndex: "status",
      ...getColumnSearchProps("status"),
      sorter: (a: ITFDataTableSerialNumber, b: ITFDataTableSerialNumber) =>
        sorterString(a.status, b.status),
      width: 150,
    },
  ];

  useEffect(() => {
    getDataSerialNumberAllUser();
  }, []);

  return (
    <>
      <HeaderManagement
        title={title}
        onClickButtonAddNew={() => {}}
        hiddenAdd={true}
      />
      <TableManagement columns={columns} data={dataSerialNumber} />
    </>
  );
};
export default SerialNumberManagent;
