import { WheelData } from "types/wheel";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

interface ITFPropsSuccessRandomed {
  isOpen: boolean;
  onClose: Function;
  product: WheelData;
}

const ModalSuccessRandomed = (props: ITFPropsSuccessRandomed) => {
  const { t } = useTranslation();

  const { isOpen = true, onClose, product } = props;
  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => onClose()}
        footer={false}
        maskClosable={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <h1>{t("Congratulations")}</h1>
          <img
            src={`${product?.showImage}`}
            alt="111"
            style={{ maxWidth: "100%", height: "auto" }}
          />
          <h1>
            {t("You got a/an")} {product?.option}
          </h1>
          <Button
            danger
            style={{ marginTop: "10px" }}
            onClick={() => onClose()}
          >
            {t("Close")}
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default ModalSuccessRandomed;
