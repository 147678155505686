import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ITFDataTableHistory } from "types/historyManagement.types";

export interface commonStage {
  dataTableHistoryCheckInReward: ITFDataTableHistory[];
  dataTableHistoryClaimInReward: ITFDataTableHistory[];
  dataTableHistoryRandomInReward: ITFDataTableHistory[];
}

const initialValues: commonStage = {
  dataTableHistoryCheckInReward: [],
  dataTableHistoryClaimInReward: [],
  dataTableHistoryRandomInReward: [],
};

const historySlice = createSlice({
  name: "randomProduct",
  initialState: initialValues,
  reducers: {
    setDataTableHistoryCheckInReward: (
      state: commonStage,
      action: PayloadAction<ITFDataTableHistory[]>
    ) => {
      state.dataTableHistoryCheckInReward = action.payload;
    },
    setDataTableHistoryClaimInReward: (
      state: commonStage,
      action: PayloadAction<ITFDataTableHistory[]>
    ) => {
      state.dataTableHistoryClaimInReward = action.payload;
    },
    setDataTableHistoryRandomInReward: (
      state: commonStage,
      action: PayloadAction<ITFDataTableHistory[]>
    ) => {
      state.dataTableHistoryRandomInReward = action.payload;
    },
  },
});

export const {
  setDataTableHistoryCheckInReward,
  setDataTableHistoryClaimInReward,
  setDataTableHistoryRandomInReward,
} = historySlice.actions;
export const historySelector = (store: RootState) => store.history;

export default historySlice.reducer;
