import { httpClient } from "utils/HttpClient";

export const getRewardClaim = async (productId?: string) => {
  return await httpClient.get("/GetProductReward", { params: { productId } });
};

export const putRewardClaimManagement = async (data: any) => {
  return await httpClient.put("/UpdateProductReward", data);
};

export const postRewardClaimManagement = async (data: any) => {
  return await httpClient.post("/AddProductReward", data);
};

export const putUserClaimReward = async (data: any) => {
  return await httpClient.put("/UserCraimReward", data);
};

export const deleteRewardClaim = async (productId: string) => {
  return await httpClient.delete("/DeleteProductReward", {
    params: { productId },
  });
};
