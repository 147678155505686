import React, { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, Table, Upload } from "antd";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { getFileLogs, importUser } from "api/user/user";
import { PAGE_KEY } from "constant";
import ModalError from "common/modal/modalError";
import { get, isEmpty } from "lodash";
import { useCommon, useCommonSuccess } from "hooks/common/useCommon";
import ModalSuccess from "common/modal/modalSuccess";
import ModalConfirmation from "common/modal/modalConfirmation";
import { useTranslation } from "react-i18next";
import { ITFDataGetFileLog } from "types/importUser.types";
import { sorterNumber, sorterString } from "helper/sorter";
import type { ColumnsType } from "antd/es/table";
import { showFormatDate } from "helper/formatter";
import { SearchTable } from "component/searchTable";

const { Dragger } = Upload;

const App: React.FC = () => {
  const { t } = useTranslation();
  const { getColumnSearchProps } = SearchTable();
  const [file, setFile] = useState<{
    file: string;
    fileName: string;
  }>({ file: "", fileName: "" });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [fileLogs, setFileLogs] = useState([]);
  const [showModalConfirmation, setShowModalConfirmation] =
    useState<boolean>(false);
  const { isShowError, errorDetail, hideError } = useCommon(
    PAGE_KEY.ADMIN_ADD_USER
  );
  const { showError, setErrorTitle, setErrorMessage, setErrorCode } = useCommon(
    PAGE_KEY.ADMIN_ADD_USER
  );
  const { isShowSuccess, successDetail, hideSuccess } = useCommonSuccess(
    PAGE_KEY.ADMIN_ADD_USER
  );
  const { showSuccess, setSuccessTitle } = useCommonSuccess(
    PAGE_KEY.ADMIN_ADD_USER
  );
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onChangeImportFile: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList[0]?.originFileObj !== undefined) {
      setFile({
        file: (await getBase64(newFileList[0].originFileObj as RcFile)).split(
          ","
        )[1],
        fileName: newFileList[0].name,
      });
    } else {
      setFile({ file: "", fileName: "" });
    }
    setFileList(newFileList);
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    onChange(info) {
      onChangeImportFile(info);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    beforeUpload(file, FileList) {
      return false;
    },
    maxCount: 1,
    fileList: fileList,
  };

  const onClickConfirmImport = async () => {
    setLoadingImport(true);
    try {
      await importUser(file);
      setSuccessTitle(t("Import User Success"));
      showSuccess();
      getDataFileLogs();
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitle(t("Import User Error"));
      setErrorCode(error.response.status);
      setErrorMessage(message);
      showError();
    } finally {
      setShowModalConfirmation(false);
      setLoadingImport(false);
      setFile({ file: "", fileName: "" });
      setFileList([]);
    }
  };

  const getDataFileLogs = async () => {
    try {
      const response = await getFileLogs();
      const data = response.data.map((item: ITFDataGetFileLog) => {
        return {
          ...item,
          createDate: showFormatDate(item.createDate),
        };
      });

      setFileLogs(data);
    } catch (error) {}
  };

  useEffect(() => {
    getDataFileLogs();
  }, []);

  const columns: ColumnsType<ITFDataGetFileLog> = [
    {
      title: t("File Name"),
      key: "fileName",
      dataIndex: "fileName",
      ...getColumnSearchProps("fileName"),
      sorter: (a: ITFDataGetFileLog, b: ITFDataGetFileLog) =>
        sorterString(a.fileName, b.fileName),
      width: 150,
    },
    {
      title: t("Count of Upload"),
      key: "extNumber",
      dataIndex: "extNumber",
      ...getColumnSearchProps("extNumber"),
      sorter: (a: ITFDataGetFileLog, b: ITFDataGetFileLog) =>
        sorterNumber(a.extNumber, b.extNumber),
      width: 150,
    },
    {
      title: t("Uploaded By"),
      key: "createBy",
      dataIndex: "createBy",
      ...getColumnSearchProps("createBy"),
      sorter: (a: ITFDataGetFileLog, b: ITFDataGetFileLog) =>
        sorterString(a.createBy, b.createBy),
      width: 150,
    },
    {
      title: t("Uploaded Date"),
      key: "createDate",
      dataIndex: "createDate",
      ...getColumnSearchProps("createDate"),
      sorter: (a: ITFDataGetFileLog, b: ITFDataGetFileLog) =>
        sorterString(a.createDate, b.createDate),
      width: 150,
    },
  ];

  return (
    <div>
      <h1>{t("Import Data User")}</h1>

      <Dragger {...props} disabled={loadingImport}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        {loadingImport ? (
          <p className="ant-upload-text">{t("Importing")}...</p>
        ) : (
          <>
            <p className="ant-upload-text">
              {t("Click or drag file to this area to upload")}
            </p>
          </>
        )}
      </Dragger>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          type="primary"
          onClick={() => {
            setShowModalConfirmation(true);
          }}
          disabled={isEmpty(file.file) || loadingImport}
          style={{ width: "200px" }}
        >
          {t("Upload")}
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={fileLogs}
        style={{ marginTop: "20px" }}
        pagination={{
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      ></Table>

      <ModalError
        isOpen={isShowError}
        onCancel={() => hideError()}
        code={errorDetail.code}
        title={errorDetail.title}
        message={errorDetail.message}
      ></ModalError>

      <ModalSuccess
        isOpen={isShowSuccess}
        onCancel={() => hideSuccess()}
        title={successDetail.title}
        message={successDetail.message}
      ></ModalSuccess>

      <ModalConfirmation
        isOpen={showModalConfirmation}
        onCancel={() => setShowModalConfirmation(false)}
        onOK={() => onClickConfirmImport()}
        title={t("Do you want to upload file ?")}
        message={file.fileName}
        loading={loadingImport}
        type="common"
      ></ModalConfirmation>
    </div>
  );
};

export default App;
