import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Button } from "antd";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import { logout } from "api/common/authentication";
import { commonSelector } from "store/slice/common";
import { useSelector } from "react-redux";
import { lowerCase } from "lodash";
import colorConstants from "constant/colorConstants";

const { Header } = Layout;

interface ITFProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  hiddenExpandSider: boolean;
}
const HeaderLayout = (props: ITFProps) => {
  const { t } = useTranslation();
  const { collapsed, setCollapsed, hiddenExpandSider } = props;
  const userData = useSelector(commonSelector).dataUser;
  return (
    <Header
      style={{
        padding: 0,
        top: 0,
        zIndex: 1,
        width: "100%",
        lineHeight: "unset",
      }}
    >
      <Container>
        {hiddenExpandSider ? (
          <div></div>
        ) : (
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
              display: hiddenExpandSider ? "none" : "",
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginRight: "20px",
              background: colorConstants.Primary50,
              padding: "2px 8px",
              borderRadius: "8px",
              border: `2px solid ${colorConstants.BaseBlack} `,
            }}
          >
            <div>
              {t("Username")} : {lowerCase(userData?.userId)}
            </div>
            <div>
              {t("Role")} : {userData?.role}
            </div>
          </div>
          <Button type="primary" danger onClick={() => logout()}>
            {t("Log Out")}
          </Button>
        </div>
      </Container>
    </Header>
  );
};

export default HeaderLayout;
