import { Button, Drawer, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import colorConstants from "constant/colorConstants";
import { useTranslation } from "react-i18next";
import ShowUserInfo from "../showUserInfoHeader/useShowUserInfo";

const { Text } = Typography;

const ShowHeaderMobile = () => {
  const { t } = useTranslation();

  const [isShowDrawerMenu, setIsShowDrawerMenu] = useState<boolean>(false);
  const showMenuDrawer = (label: string, path: string) => {
    return (
      <Text>
        <Link to={path} onClick={() => setIsShowDrawerMenu(false)}>
          <div
            className="titleHeader"
            style={{ color: colorConstants.BaseBlack }}
          >
            {label}
          </div>
        </Link>
      </Text>
    );
  };

  return (
    <>
      <Button type="primary">
        <MenuOutlined onClick={() => setIsShowDrawerMenu(true)} />
      </Button>
      <ShowUserInfo></ShowUserInfo>
      <Drawer
        open={isShowDrawerMenu}
        placement="right"
        extra={
          <Button onClick={() => setIsShowDrawerMenu(false)}>
            {t("Close")}
          </Button>
        }
        closeIcon={<></>}
        title={t("Menu")}
      >
        <div>
          {showMenuDrawer(t("Home Page"), "")}
          {showMenuDrawer(t("Random Reward"), "random-reward")}
          {showMenuDrawer(t("Claim Reward"), "claim-reward")}
        </div>
      </Drawer>
    </>
  );
};

export default ShowHeaderMobile;
