import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Upload,
  FormInstance,
  ColorPicker,
} from "antd";
import { useTranslation } from "react-i18next";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useEffect, useState } from "react";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Numeric } from "helper/inputNumber";
import { useAppDispatch } from "store/store";
import { setImgRandomProduct } from "store/slice/randomProduct";
import { ITFDataTableRandomReward } from "types/rewardRandom.types";

interface ITFPropsDispalyDrawerRewardRandom {
  form: FormInstance;
  dataEdit?: ITFDataTableRandomReward;
  IsOpen: boolean;
  allPercent: number;
}

export const DisplayDrawer = (props: ITFPropsDispalyDrawerRewardRandom) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { form, dataEdit, IsOpen, allPercent } = props;

  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    if (IsOpen) {
      if (dataEdit) {
        setFileList([
          {
            uid: "-1",
            name: dataEdit.imageName,
            status: "done",
            url: `data:image/jpeg;base64,${dataEdit.imageBase64}`,
          },
        ]);
        dispatch(
          setImgRandomProduct({
            image: dataEdit.imageBase64,
            imageType: dataEdit.imageType,
            imageName: dataEdit.imageName,
          })
        );
      } else {
        setFileList([]);
      }
    }
  }, [form, dataEdit, dispatch, IsOpen]);

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList[0]?.originFileObj !== undefined) {
      dispatch(
        setImgRandomProduct({
          image: (
            await getBase64(newFileList[0].originFileObj as RcFile)
          ).split(",")[1],
          imageType: newFileList[0].originFileObj?.type,
          imageName: newFileList[0].originFileObj?.name,
        })
      );
    } else {
      dispatch(setImgRandomProduct({}));
    }

    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      {fileList.length > 0 ? <CloseCircleOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{fileList.length > 0 ? "" : "Upload"}</div>
    </div>
  );

  const showAvailablePercent = () => {
    if (dataEdit) {
      return 100 - allPercent + dataEdit.percent;
    }
    return 100 - allPercent;
  };

  return (
    <>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Reward Name")}
            name="title"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Reward Name"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Percent")}
            name="percent"
            rules={[
              { required: true, message: t("Please enter") + t("Percent") },
            ]}
            style={{ marginBottom: "unset" }}
          >
            <Numeric
              isAllowed={(inputObj: any) => {
                const { value } = inputObj;
                return value >= 0 && value <= showAvailablePercent();
              }}
            />
          </Form.Item>
          <div style={{ marginBottom: "24px" }}>
            {t("All percent not more than 100%")} ({t("Remaining")}{" "}
            {showAvailablePercent()}%)
          </div>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={t("Font Color")} name="fontColor">
            <ColorPicker />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Background Color")}
            name="backgroundColor"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Background Color"),
              },
            ]}
          >
            <ColorPicker />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={t("Font Size")} name="fontSize">
            <Numeric
              isAllowed={(inputObj: any) => {
                const { value } = inputObj;
                return value >= 0 && value <= 100;
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Row>
            <Form.Item label={t("Image")} name="image">
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                beforeUpload={() => false}
                onChange={handleChange}
                onPreview={handlePreview}
                openFileDialogOnClick={fileList.length > 0 ? false : true}
                fileList={fileList}
              >
                {uploadButton}
              </Upload>
            </Form.Item>

            <Modal
              open={previewOpen}
              footer={null}
              onCancel={() => setPreviewOpen(false)}
              title={previewTitle}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </Row>
        </Col>
      </Row>
    </>
  );
};
