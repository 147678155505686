import { putRandomPoint } from "api/rewardRandom/rewardRandom";
import { get } from "lodash";
import { useCommon, useCommonSuccess } from "hooks/common/useCommon";
import { PAGE_KEY } from "constant";
import { useTranslation } from "react-i18next";
import { parser } from "helper/formatter";

export const useSetRandomPoint = (onCancel: Function) => {
  const { t } = useTranslation();

  const { showError, setErrorTitle, setErrorMessage, setErrorCode } = useCommon(
    PAGE_KEY.ADMIN_EDIT_POINT_PRODUCT_RANDOM
  );

  const { showSuccess, setSuccessTitle } = useCommonSuccess(
    PAGE_KEY.ADMIN_EDIT_POINT_PRODUCT_RANDOM
  );

  const onFinish = async (value: any) => {
    try {
      await putRandomPoint(Number(parser(value.randomPoint)));
      setSuccessTitle(t("Edit Point Random Product Success"));
      showSuccess();
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitle(t("EDIT Point Random Product Error"));
      setErrorCode(error.response.status);
      setErrorMessage(message);
      showError();
    } finally {
      onCancel();
    }
  };

  return { onFinish };
};
