import { Wheel } from "react-custom-roulette";
import { useEffect, useMemo, useState } from "react";
import { Container } from "./styles";
import colorConstants from "constant/colorConstants";
import { useReward } from "hooks/rewardRandom/useRewardRandom";
import { ITFResponseDataRandomProduct } from "types/rewardRandom.types";
import ModalSuccessRandomed from "./modalSuccessRandomed";
import { Button, Col, Row, Tooltip } from "antd";
import { commonSelector } from "store/slice/common";
import { useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { WheelData } from "types/wheel";
import { useCookies } from "react-cookie";
import { Typography } from "antd";
import ModalError from "common/modal/modalError";
import { useCommon } from "hooks/common/useCommon";
import { PAGE_KEY } from "constant";
import { useTranslation } from "react-i18next";
import ModalCheckIn from "common/modal/modalCheckIn";
import { LOGOUFA } from "constant/index";
import { Pointer } from "constant/index";
import styled from "styled-components";
import { isShowFeatureCheckIn } from "constant/common";
const { Text } = Typography;

const { Title } = Typography;
const RandomRewardPage = () => {
  const { t } = useTranslation();
  const [cookies] = useCookies(["accessToken", "userInfo"]);
  const isHaveToken = cookies.accessToken ? true : false;
  const [mustSpin, setMustSpin] = useState<boolean>(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const userPoint = useSelector(commonSelector).dataUser?.totalPoint || 0;
  const isLoading = useSelector(commonSelector).loading;
  const { isShowError, errorDetail, hideError } = useCommon(
    PAGE_KEY.CUS_RANDOM_PRODUCT
  );

  const {
    getDataProductRandom,
    dataRandomProduct,
    handleUserRandomedProduct,
    showModalSuccessRandomed,
    onCloseModalSuccessRandomed,
    isPostDataRandomProduct,
    setIsPostDataRandomProduct,
    getDataCheckUserCountProductRandom,
    isLimitCountPerDay,
    getDataRandomPoint,
    randomPoint,
  } = useReward(() => {});

  const RainbowBorder = styled.div`
    display: inline-block;
    padding: 10px;
    background: linear-gradient(
      45deg,
      red,
      orange,
      yellow,
      green,
      blue,
      indigo,
      violet
    );
    border-radius: 50%;

    > div {
      border-radius: 50%;
    }
  `;

  const handleSpinClick = () => {
    try {
      const expanded = dataProduct?.flatMap((item) =>
        Array(item.percent).fill(item)
      );

      const winner = expanded[Math.floor(Math.random() * expanded.length)];
      setPrizeNumber(winner.id);
      setMustSpin(true);
      setIsPostDataRandomProduct(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataProductRandom();
    getDataRandomPoint();
  }, [getDataProductRandom, getDataRandomPoint]);

  useEffect(() => {
    if (cookies?.userInfo?.nameid) {
      getDataCheckUserCountProductRandom(cookies.userInfo.nameid);
    }
  }, [cookies?.userInfo?.nameid, getDataCheckUserCountProductRandom]);

  const startColor = colorConstants.Primary50;
  const endColor = colorConstants.Primary500;

  function createGradation(start: string, end: string, steps: number) {
    const startR = parseInt(start.substring(1, 3), 16);
    const startG = parseInt(start.substring(3, 5), 16);
    const startB = parseInt(start.substring(5, 7), 16);

    const endR = parseInt(end.substring(1, 3), 16);
    const endG = parseInt(end.substring(3, 5), 16);
    const endB = parseInt(end.substring(5, 7), 16);

    const deltaR = (endR - startR) / (steps - 1);
    const deltaG = (endG - startG) / (steps - 1);
    const deltaB = (endB - startB) / (steps - 1);

    return Array.from({ length: steps }, (_, i) => {
      const r = Math.round(startR + deltaR * i);
      const g = Math.round(startG + deltaG * i);
      const b = Math.round(startB + deltaB * i);
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    });
  }

  const dataProduct: WheelData[] = useMemo(() => {
    const gradation = createGradation(
      startColor,
      endColor,
      dataRandomProduct.length
    );
    const data: WheelData[] = dataRandomProduct?.map(
      (item: ITFResponseDataRandomProduct, count: number) => {
        return {
          id: item.id,
          option: item?.title,
          percent: item?.percent,
          image: {
            uri: `data:image/jpeg;base64,${item.imageBase64}`,
            landscape: true,
            sizeMultiplier: 0.6,
          },
          productId: item.productId,
          style: {
            fontSize: item.frontSize,
            backgroundColor: gradation[count],

            textColor: item.textColor,
          },
          showImage: `data:image/jpeg;base64,${item.imageBase64}`,
        };
      }
    );

    return data;
  }, [dataRandomProduct]);

  useEffect(() => {
    if (isPostDataRandomProduct && !mustSpin) {
      const productByPrizeNumber = dataProduct.find(
        (item) => item.id === prizeNumber
      );
      handleUserRandomedProduct(productByPrizeNumber?.productId);
      setIsPostDataRandomProduct(false);
    }
  }, [
    dataProduct,
    handleUserRandomedProduct,
    isPostDataRandomProduct,
    mustSpin,
    prizeNumber,
    setIsPostDataRandomProduct,
  ]);

  const handleShowTooltip = () => {
    let wordTooltip: string = "";
    let showTooltip: boolean = false;
    if (userPoint === 0 || userPoint < randomPoint) {
      wordTooltip = t("You don't have point");
      showTooltip = true;
    }
    if (isLimitCountPerDay) {
      wordTooltip = t("Is limit per day");
      showTooltip = true;
    }
    return (
      <Tooltip title={wordTooltip}>
        <InfoCircleOutlined
          style={{
            marginLeft: "10px",
            display: showTooltip ? "" : "none",
          }}
        />
      </Tooltip>
    );
  };

  return (
    <>
      {!isLoading ? (
        <Container>
          <Title level={3}>{t("วงล้อรับโชค")}</Title>
          {dataProduct.length > 0 ? (
            <div>
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    width: "100%",
                    position: "absolute",
                    height: "100%",
                    zIndex: 9,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      marginBottom: 5,
                      width: 80,
                      height: 80,
                      background: "currentColor",
                      filter: "drop-shadow(-10px 30px 5px #000000CC)",
                      padding: "20px 5px",
                      clipPath: "circle(50% at 50% 50%)",
                    }}
                    src={LOGOUFA}
                    alt="logo"
                  />
                </div>
                <RainbowBorder>
                  <Wheel
                    pointerProps={{
                      src: Pointer,
                      style: {
                        transform: "rotate(50deg)",
                        width: "15%",
                        height: "25%",
                        right: 12,
                        top: 5,
                      },
                    }}
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={dataProduct}
                    onStopSpinning={() => {
                      setMustSpin(false);
                    }}
                    // สี ขอบ
                    outerBorderColor={colorConstants.BaseBlack}
                    // ขนาด เส้นขอบ
                    outerBorderWidth={15}
                    innerBorderColor="yellow"
                    // สี เส้นแบ่งข้างใน
                    radiusLineColor={colorConstants.BaseBlack}
                    // ขนาด เส้นแบ่งข้างใน
                    radiusLineWidth={8}
                    // สี ตัวอักษร
                    textColors={["black"]}
                    // ขนาด ตัวอักษร
                    fontSize={50}
                    perpendicularText={true}
                    // backgroundColors={[
                    //   colorConstants.Primary300,
                    // colorConstants.BaseGray,
                    // colorConstants.Primary300,
                    // colorConstants.Primary300,
                    // colorConstants.Primary300,
                    // colorConstants.Primary300,
                    // ]}
                    // backgroundColors={["#a0666644"]}
                  />
                </RainbowBorder>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Title level={3}>
              {randomPoint} / {t("Spin")}
            </Title>
            <Row>
              <Button
                type="primary"
                onClick={handleSpinClick}
                disabled={
                  mustSpin ||
                  userPoint === 0 ||
                  userPoint < randomPoint ||
                  isLimitCountPerDay ||
                  randomPoint <= 0
                }
              >
                {t("Spin")}
              </Button>
              {handleShowTooltip()}
            </Row>
            <Tooltip
              title={
                <Col>
                  <div>
                    1.หมุนวงล้อโดยใช้ {randomPoint} แต้มบนระบบต่อการหมุนรางวัล 1
                    ครั้ง
                  </div>
                  <div>
                    2.หากสุ่มได้เป็นพ้อยหรือเครดิตฟรีจะได้รับยอดภายในวันถัดไป
                  </div>
                  <div>
                    3.หากสุ่มได้ของรางวัลที่ต้องทำการจัดส่งโดยระบบขนส่งสามารถติดตามผลได้ผ่านทางประวัติการรับรางวัล
                    ใช้เวลาไม่เกิน 7 วัน
                  </div>
                  <div>4.หมุนลุ้นรับรางวัลสูงสุด 5 ครั้งต่อวัน</div>
                  <div>5.การตัดสินใจของทีมงานถือเป็นที่สิ้นสุด</div>
                </Col>
              }
              trigger="click"
              placement="bottomLeft"
            >
              <Text
                underline
                style={{
                  cursor: "pointer",
                  color: colorConstants.Primary500,
                  marginTop: "5px",
                }}
              >
                {t("Conditions for accepting prizes")}
              </Text>
            </Tooltip>
          </div>
        </Container>
      ) : (
        <></>
      )}

      <ModalSuccessRandomed
        isOpen={showModalSuccessRandomed}
        onClose={onCloseModalSuccessRandomed}
        product={dataProduct[prizeNumber]}
      ></ModalSuccessRandomed>

      <ModalError
        isOpen={isShowError}
        onCancel={() => hideError()}
        code={errorDetail.code}
        title={errorDetail.title}
        message={errorDetail.message}
      ></ModalError>

      {isHaveToken && isShowFeatureCheckIn ? (
        <ModalCheckIn></ModalCheckIn>
      ) : (
        <></>
      )}
    </>
  );
};

export default RandomRewardPage;
