import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import { registerUserImport } from "api/user/user";
import { useState } from "react";
import { useCommon } from "hooks/common/useCommon";
import { get } from "lodash";
import { PAGE_KEY } from "constant";

interface ITFProps {
  open: boolean;
  onClose: Function;
}

const ModalFirstLogin = (props: ITFProps) => {
  const { t } = useTranslation();
  const { open, onClose } = props;
  const [loadingSubmitFirstRegister, setLoadingSubmitFirstRegister] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const { showError, setErrorTitle, setErrorMessage, setErrorCode } = useCommon(
    PAGE_KEY.CUS_REGISTER
  );

  const onFinish = async (value: any) => {
    setLoadingSubmitFirstRegister(true);
    const data = {
      userId: value.userId,
      password: value.password,
      firstName: value.firstName,
      lastName: value.lastName,
      address: value.address,
      lineId: value.lineId,
      phoneNumber: value.phoneNumber,
      phoneNumberSecond: value.phoneNumberSecond,
    };

    try {
      await registerUserImport(data);
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitle("Customer Register");
      setErrorCode(error.response.status);
      setErrorMessage(message);
      showError();
    } finally {
      form.resetFields();
      setLoadingSubmitFirstRegister(false);
      onClose();
    }
  };
  return (
    <Modal
      open={open}
      footer={false}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      title="First Login"
      maskClosable={false}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={t("Username")}
              name="userId"
              rules={[
                {
                  required: true,
                  message: t("Please enter") + t("Username"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label={t("First name")}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t("Please enter") + t("First name"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label={t("Last name")}
              name="lastName"
              rules={[
                { required: true, message: t("Please enter") + t("Last name") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name="password"
              label={t("Password")}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                { min: 5, message: "min is 5" },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name="confirmPassword"
              label={t("Confirm Password")}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Col span={24}>
          <Form.Item label={t("Address")} name="address">
            <TextArea />
          </Form.Item>
        </Col>

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={t("Phone Number")} name="phoneNumber">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label={t("Phone Number Second")}
              name="phoneNumberSecond"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={12}>
            <Form.Item label={`${t("Line ID")} (${t("User")})`} name="lineId">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ justifyContent: "flex-end", gap: "8px" }}>
          <Button
            onClick={() => {
              onClose();
              form.resetFields();
            }}
            danger
            loading={loadingSubmitFirstRegister}
          >
            {t("Cancel")}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={loadingSubmitFirstRegister}
          >
            {t("Submit")}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalFirstLogin;
