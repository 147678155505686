import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import common from "store/slice/common";
import ramdomProduct from "store/slice/randomProduct";
import commonSuccess from "store/slice/commonSuccess";
import history from "store/slice/history";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cart"],
};

const rootReducer = combineReducers({
  common,
  ramdomProduct,
  commonSuccess,
  history,
});

export default persistReducer(persistConfig, rootReducer);
