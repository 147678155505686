import { Numeric } from "helper/inputNumber";
import { Col, Divider, Form, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { commonSelector } from "store/slice/common";
import { TYPE_ROLE } from "constant";

interface ITFProps {
  dataEdit: any;
}

export const DisplayDrawerUser = (props: ITFProps) => {
  const { dataEdit } = props;
  const { t } = useTranslation();
  const userRole = useSelector(commonSelector).dataUser?.role || "";

  return (
    <>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={t("First name")} name="firstName">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={t("Last name")} name="lastName">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Username")}
            name="userId"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Username"),
              },
            ]}
          >
            <Input disabled={dataEdit === undefined ? false : true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Password")}
            name="password"
            rules={[
              { required: false, message: t("Please enter") + t("Password") },
              { min: 5, message: t("Enter at least 5 characters") + "" },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Col span={24}>
        <Form.Item label={t("Address")} name="address">
          <TextArea />
        </Form.Item>
      </Col>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={t("Phone Number")} name="phoneNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={t("Phone Number Second")} name="phoneNumberSecond">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label={`${t("Line ID")} (${t("User")})`} name="lineId">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("Source")} name="source">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <div hidden={userRole === TYPE_ROLE.STAFF ? true : false}>
        <Divider />

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label={t("Total Deposit")} name="totalDeposit">
              <Numeric disable />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label={t("Total Bonus")} name="totalBonus">
              <Numeric disable />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label={t("Total Withdraw")} name="totalWithdraw">
              <Numeric disable />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label={t("Deposit Amount")} name="depositAmount">
              <Numeric disable />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label={t("Bonus Amount")} name="bonusAmount">
              <Numeric disable />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label={t("Withdraw Amount")} name="withDrawAmount">
              <Numeric disable />
            </Form.Item>
          </Col>
        </Row>

        <Col span={8}>
          <Form.Item
            label={t("Total Point")}
            name="totalPoint"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Total Point"),
              },
            ]}
          >
            <Numeric
              isAllowed={(inputObj: any) => {
                const { value } = inputObj;
                return value >= 0 && value <= 100000;
              }}
            />
          </Form.Item>
        </Col>
      </div>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Status")}
            name="valiStatus"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Status"),
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={[
                { value: "A", label: t("Active") },
                { value: "C", label: t("Un Active") },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
