import { FormInstance } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useRewardCheckInManagement = (form: FormInstance) => {
  const { t } = useTranslation();

  const title = t("Check In Reward");
  const [isOperDrawer, setIsOperDrawer] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<any | undefined>(undefined);

  const onOpenDrawer = () => {
    setIsOperDrawer(true);
  };

  const onCloseDrawer = () => {
    setIsOperDrawer(false);
    setDataEdit(undefined);
  };

  const onClickEdit = (record: any) => {
    setIsOperDrawer(true);
    setDataEdit(record);
  };

  const handleSetFieldsEdit = () => {
    if (dataEdit?.checkinType === "CI01" || dataEdit?.checkinType === "CI03") {
      form.setFieldsValue({
        point: dataEdit?.product,
      });
    }
    form.setFieldsValue({
      date: dataEdit?.dateOfMonth,
      type: dataEdit?.checkinType,
    });
  };

  return {
    title,
    onOpenDrawer,
    onCloseDrawer,
    isOperDrawer,
    dataEdit,
    handleSetFieldsEdit,
    onClickEdit,
  };
};
