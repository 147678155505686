import {
  ITFDataTableRandomReward,
  ITFResponseDataRandomProduct,
} from "types/rewardRandom.types";
import {
  deleteRandomedProduct,
  getProductRandom,
  postProductRandomManagement,
  postUserRandomedProduct,
  putProductRandomManagement,
} from "api/rewardRandom/rewardRandom";
import { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { useUserInfo } from "../user/userUserInfo";
import { useAppDispatch } from "store/store";
import { useSelector } from "react-redux";
import {
  randomProductSelector,
  setImgRandomProduct,
} from "store/slice/randomProduct";
import { setLoading } from "store/slice/common";
import { getCheckUserCountProductRandom, getRandomPoint } from "api/user/user";
import { PAGE_KEY } from "constant";
import { useCommon, useCommonSuccess } from "../common/useCommon";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

export const useReward = (onCloseDrawer: Function) => {
  const dispatch = useAppDispatch();
  const { getDataUserInfo } = useUserInfo();
  const [cookies] = useCookies(["userInfo"]);
  const { t } = useTranslation();

  const dataImageRandomProduct = useSelector(
    randomProductSelector
  ).imgRandomProduct;

  const { showError, setErrorTitle, setErrorMessage, setErrorCode } = useCommon(
    PAGE_KEY.CUS_RANDOM_PRODUCT
  );

  const [dataRandomProduct, setDataRandomProduct] = useState<
    ITFResponseDataRandomProduct[]
  >([]);
  const [dataTableRandomReward, setDataTableRandomReward] = useState<
    ITFDataTableRandomReward[]
  >([]);
  const [isPostDataRandomProduct, setIsPostDataRandomProduct] =
    useState<boolean>(false);
  const [showModalSuccessRandomed, setShowModalSuccessRandomed] =
    useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<string[]>([]);
  const [isLimitCountPerDay, setIsLimitCountPerDay] = useState<boolean>(false);
  const [randomPoint, setRandomPoint] = useState<number>(0);
  const {
    showError: showErrorProductRandom,
    setErrorTitle: setErrorTitleProductRandom,
    setErrorMessage: setErrorMessageProductRandom,
    setErrorCode: setErrorCodeProductRandom,
  } = useCommon(PAGE_KEY.ADMIN_ADD_PRODUCT_RANDOM);

  const { showSuccess, setSuccessTitle } = useCommonSuccess(
    PAGE_KEY.ADMIN_ADD_PRODUCT_RANDOM
  );

  const getDataProductRandom = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const response = await getProductRandom();
      const dataProduct: ITFResponseDataRandomProduct[] = response.data.map(
        (item: any, count: number) => {
          return {
            key: `${count}`,
            id: count,
            ...item,
          };
        }
      );
      setDataTableRandomReward(dataProduct);
      setDataRandomProduct(dataProduct);
      setSelectedDeleteId([]);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  const onFinishProductRandomManagement = async (data: any, dataEdit: any) => {
    const dataPost = [
      {
        title: data.title,
        percent: data.percent,
        imageBase64: dataImageRandomProduct.image,
        imageType: dataImageRandomProduct.imageType,
        imageName: dataImageRandomProduct.imageName,
        createBy: cookies.userInfo.nameid,
        bgColor:
          typeof data?.backgroundColor == "object"
            ? data?.backgroundColor?.toRgbString()
            : data?.backgroundColor,
        textColor:
          typeof data?.fontColor == "object"
            ? data?.fontColor?.toRgbString()
            : data?.fontColor,
        frontSize: data.fontSize,
      },
    ];

    try {
      if (dataEdit !== undefined) {
        const dataPut = { ...dataPost[0], productId: dataEdit.productId };
        await putProductRandomManagement(dataPut);
      } else {
        await postProductRandomManagement(dataPost);
      }
      dispatch(setImgRandomProduct({}));
      getDataProductRandom();
      onCloseDrawer();

      setSuccessTitle(t("Add/Edit Random Product Success"));
      showSuccess();
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitleProductRandom(t("ADD/EDIT Random Product Error"));
      setErrorCodeProductRandom(error.response.status);
      setErrorMessageProductRandom(message);
      showErrorProductRandom();
    } finally {
    }
  };

  const handleUserRandomedProduct = async (productId: string | undefined) => {
    const data = {
      userId: cookies.userInfo.nameid,
      usePoint: randomPoint,
      productId: productId,
    };
    try {
      await postUserRandomedProduct(data);
      setShowModalSuccessRandomed(true);
      getDataUserInfo();
      getDataCheckUserCountProductRandom(cookies.userInfo.nameid);
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitle(t("Random Product Error"));
      setErrorCode(error.response.status);
      setErrorMessage(message);
      showError();
    } finally {
      setIsPostDataRandomProduct(false);
    }
  };

  const onCloseModalSuccessRandomed = () => {
    setShowModalSuccessRandomed(false);
  };

  const rowSelection = {
    onChange: (
      selectedRowsKey: any,
      selectedRows: ITFDataTableRandomReward[]
    ) => {
      setSelectedDeleteId(
        selectedRows.map((item) => {
          return item.productId;
        })
      );
    },
  };

  const onClickDelete = () => {
    dispatch(setLoading(true));
    try {
      selectedDeleteId.map(async (item) => {
        try {
          await deleteRandomedProduct(cookies.userInfo.nameid, item);
        } catch (error: any) {
          const message = get(error, "response.data");
          setErrorTitleProductRandom(t("DELETE Random Product Error"));
          setErrorCodeProductRandom(error.response.status);
          setErrorMessageProductRandom(message);
          showErrorProductRandom();
        }
      });
      setTimeout(() => {
        getDataProductRandom();
      }, 500);
      setSelectedDeleteId([]);
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitleProductRandom(t("DELETE Random Product Error"));
      setErrorCodeProductRandom(error.response.status);
      setErrorMessageProductRandom(message);
      showErrorProductRandom();
    }
  };

  const getDataCheckUserCountProductRandom = useCallback(
    async (userId: string) => {
      dispatch(setLoading(true));
      try {
        await getCheckUserCountProductRandom(userId);
        setIsLimitCountPerDay(false);
      } catch (error: any) {
        if (error.response.status === 400) {
          setIsLimitCountPerDay(true);
        }
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch]
  );

  const getDataRandomPoint = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const response = await getRandomPoint();
      setRandomPoint(response.data);
    } catch (error: any) {
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  return {
    getDataProductRandom,
    dataTableRandomReward,
    onFinishProductRandomManagement,
    dataRandomProduct,
    handleUserRandomedProduct,
    showModalSuccessRandomed,
    onCloseModalSuccessRandomed,
    isPostDataRandomProduct,
    setIsPostDataRandomProduct,
    rowSelection,
    onClickDelete,
    selectedDeleteId,
    getDataCheckUserCountProductRandom,
    isLimitCountPerDay,
    getDataRandomPoint,
    randomPoint,
  };
};
