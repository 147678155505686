import { ITFDataStatusHistory } from "types/historyManagement.types";
import { Button, Col, DatePicker, Form, Row, Select, Typography } from "antd";
import colorConstants from "constant/colorConstants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "hooks/history/useHistory";
import { isEmpty } from "lodash";
const { Text } = Typography;
const { RangePicker } = DatePicker;
// ITFDataStatusHistory[]
interface ITFProps {
  dataStatusHistory: ITFDataStatusHistory[];
  seletedTabs: string;
  disableAdvanceSearch: boolean;
}
const AdvanceSearch = (props: ITFProps) => {
  const { dataStatusHistory, seletedTabs, disableAdvanceSearch } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isShowAdvanceSearch, setIsShowAdvanceSearch] = useState(false);

  const {
    getDataHistoryCheckInReward,
    getDataHistoryRandomReward,
    getDataHistoryClaimReward,
    getDataStatusDescrHistory,
  } = useHistory("");

  const onFinish = async (fieldsValue: any) => {
    await getDataStatusDescrHistory();
    const rangeValue = fieldsValue["date"];
    const startDate = !isEmpty(rangeValue) ? rangeValue[0] : null;
    const endDate = !isEmpty(rangeValue) ? rangeValue[1] : null;
    const status = fieldsValue["Status"] || undefined;
    if (seletedTabs === "random") {
      await getDataHistoryRandomReward(startDate, endDate, status);
    } else if (seletedTabs === "claim") {
      await getDataHistoryClaimReward(startDate, endDate, status);
    } else if (seletedTabs === "checkIn") {
      await getDataHistoryCheckInReward(startDate, endDate, status);
    }
  };

  const onClickClear = async () => {
    form.resetFields();
    await getDataStatusDescrHistory();
    if (seletedTabs === "random") {
      await getDataHistoryRandomReward();
    } else if (seletedTabs === "claim") {
      await getDataHistoryClaimReward();
    } else if (seletedTabs === "checkIn") {
      await getDataHistoryCheckInReward();
    }
  };

  return (
    <>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Text
          underline
          style={{
            cursor: disableAdvanceSearch ? "no-drop" : "pointer",
            color: colorConstants.Primary500,
          }}
          onClick={() => {
            disableAdvanceSearch
              ? setIsShowAdvanceSearch(false)
              : setIsShowAdvanceSearch(!isShowAdvanceSearch);
          }}
        >
          {t("Advance Search")}
        </Text>
      </div>

      <div
        style={{
          border: "1px solid black",
          borderRadius: "8px",
          padding: "24px",
          marginBottom: "20px",
          display: isShowAdvanceSearch ? "" : "none",
        }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row>
            <Col span={8}>
              <Form.Item label={t("Date")} name="date">
                <RangePicker format={"DD/MM/YYYY"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Status")} name={`Status`}>
                <Select
                  style={{ width: "100%" }}
                  options={dataStatusHistory.map((item) => ({
                    label: item.descrLocal,
                    value: item.gdCode,
                  }))}
                  allowClear
                  placeholder="เลือกสถานะ"
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <Form.Item label={t(" ")} name={`Status`}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100px", marginRight: "8px" }}
                  onClick={() => {
                    form.submit();
                  }}
                >
                  {t("Search")}
                </Button>

                <Button
                  htmlType="submit"
                  style={{ width: "100px" }}
                  onClick={() => {
                    onClickClear();
                  }}
                >
                  {t("Clear")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export default AdvanceSearch;
