let useUrl = `${window.location.protocol}//${window.location.hostname}:5145/api`;
if (
  window.location.hostname === "localhost" ||
  window.location.hostname ===
    "ec2-47-129-50-104.ap-southeast-1.compute.amazonaws.com"
) {
  useUrl = `http://ec2-18-136-120-207.ap-southeast-1.compute.amazonaws.com`;
} else if (window.location.hostname === "www.222mall.vip") {
  useUrl = `https://www.222mall.vip/service`;
}

export const isShowFeatureCheckIn = false;

export const apiUrl = useUrl + "/api/v1";
