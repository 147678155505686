import { FormInstance } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ITFResponseRewardClaim } from "@/types/rewardClaim.types";

export const useRewardClaimManagement = (form: FormInstance) => {
  const { t } = useTranslation();

  const title = t("Reward Claim");
  const [isOperDrawer, setIsOperDrawer] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<ITFResponseRewardClaim | undefined>(
    undefined
  );

  const onOpenDrawer = () => {
    setIsOperDrawer(true);
  };

  const onCloseDrawer = () => {
    setIsOperDrawer(false);
    setDataEdit(undefined);
  };

  const onClickEdit = (record: ITFResponseRewardClaim) => {
    setIsOperDrawer(true);
    setDataEdit(record);
  };

  const handleSetFieldsEdit = () => {
    form.setFieldsValue({
      title: dataEdit?.title,
      description: dataEdit?.description,
      image: dataEdit?.imageBase64,
      point: dataEdit?.point,
      productQTY: dataEdit?.productQTY,
    });
  };

  return {
    title,
    onOpenDrawer,
    onCloseDrawer,
    isOperDrawer,
    dataEdit,
    handleSetFieldsEdit,
    onClickEdit,
  };
};
