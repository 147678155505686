import { Button, Modal, Row, Typography } from "antd";
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import colorConstants from "constant/colorConstants";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useMemo } from "react";

const { Title } = Typography;

interface ITFPropsModalError {
  isOpen: boolean;
  onCancel: Function;
  title: string | null;
  message?: string | null;
  onOK: Function;
  loading: boolean;
  type: "delete" | "common";
}

const ModalConfirmation = (props: ITFPropsModalError) => {
  const { isOpen, onCancel, title, message, onOK, loading, type } = props;
  const { t } = useTranslation();

  const colorModal = useMemo(() => {
    if (type === "delete") {
      return "red";
    }
    return colorConstants.Primary500;
  }, [type]);

  return (
    <Modal open={isOpen} onCancel={() => onCancel()} footer={false}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        {type === "delete" ? (
          <ExclamationCircleOutlined
            style={{ fontSize: "50px", color: colorModal }}
          />
        ) : (
          <QuestionCircleOutlined
            style={{ fontSize: "50px", color: colorModal }}
          />
        )}

        <Title style={{ color: colorModal, textAlign: "center" }}>
          {title}
        </Title>
        {message ?? <Title level={4}>{message}</Title>}
        <Row
          style={{ gap: "20px", marginTop: isEmpty(message) ? "0px" : "20px" }}
        >
          <Button onClick={() => onCancel()} danger loading={loading}>
            {t("Cancel")}
          </Button>

          <Button
            type="primary"
            danger={type === "delete" ? true : false}
            onClick={() => onOK()}
            loading={loading}
          >
            {t("Confirm")}
          </Button>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
