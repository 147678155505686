import { Button, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useState } from "react";
import ModalLogin from "../../../login/modalLogin";
import ShowUserInfo from "./useShowUserInfo";
import { useCommon } from "hooks/common/useCommon";
import { PAGE_KEY } from "constant/index";
import ModalError from "common/modal/modalError";

const ShowUserInfoHeader = () => {
  const { t } = useTranslation();
  const [cookies] = useCookies(["accessToken"]);
  const [isShowModalLoginCustomer, setIsShowModalLoginCustomer] =
    useState<boolean>(false);
  const { isShowError, errorDetail, hideError } = useCommon(PAGE_KEY.LOGIN);

  return (
    <>
      {cookies.accessToken ? (
        <div className="RowCustomer">{<ShowUserInfo></ShowUserInfo>}</div>
      ) : (
        <Row style={{ justifyContent: "flex-end" }} className="RowCustomer">
          <div>
            <Button
              type="primary"
              onClick={() => setIsShowModalLoginCustomer(true)}
              className="buttonLogin"
            >
              {t("Log In")}
            </Button>
          </div>
        </Row>
      )}

      <ModalLogin
        isOpen={isShowModalLoginCustomer}
        onClose={() => setIsShowModalLoginCustomer(false)}
      />

      <ModalError
        isOpen={isShowError}
        onCancel={() => hideError()}
        code={errorDetail.code}
        title={errorDetail.title}
        message={errorDetail.message}
      ></ModalError>
    </>
  );
};
export default ShowUserInfoHeader;
