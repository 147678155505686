import { ITFDataTableAdmin } from "types/adminManagement.types";
import { FormInstance } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useStaffManagement = (form: FormInstance) => {
  const { t } = useTranslation();

  const title = t("Staff");
  const [isOperDrawer, setIsOperDrawer] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<ITFDataTableAdmin | undefined>(
    undefined
  );

  const onOpenDrawer = () => {
    setIsOperDrawer(true);
  };

  const onCloseDrawer = () => {
    setIsOperDrawer(false);
    setDataEdit(undefined);
  };

  const onClickEdit = (record: ITFDataTableAdmin) => {
    setIsOperDrawer(true);
    setDataEdit(record);
  };

  const handleSetFieldsEditUser = () => {
    form.setFieldsValue({
      firstName: dataEdit?.firstName,
      lastName: dataEdit?.lastName,
      userId: dataEdit?.userId,
      password: dataEdit?.password,
      valiStatus: dataEdit?.valiStatus,
    });
  };

  return {
    title,
    onOpenDrawer,
    onCloseDrawer,
    isOperDrawer,
    dataEdit,
    handleSetFieldsEditUser,
    onClickEdit,
  };
};
