import { ITFDataTableRewardCheckIn } from "types/rewardCheckIn.type";
import {
  getAllCheckInInit,
  getLastDayOfMonth,
  putCheckInInit,
} from "api/rewardCheckIn/rewardCheckIn";
import { useState } from "react";
import { getRewardClaim } from "api/rewardClaim/rewardClaim";
import { showFormatDate } from "helper/formatter";
import { ITFDataCheckInType } from "types/rewardCheckIn.type";
import { getRewardCheckIn } from "api/rewardCheckIn/rewardCheckIn";
import { setLoading } from "store/slice/common";
import { useAppDispatch } from "store/store";
import { useCommon, useCommonSuccess } from "../common/useCommon";
import { PAGE_KEY } from "constant";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  setImgRewardClaim,
  randomProductSelector,
} from "store/slice/randomProduct";
export const useRewardCheckIn = (
  onCloseDrawer: Function,
  dataSelectedRewardProduuct: string
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [dataRewardCheckIn, setDataRewardCheckIn] = useState<any[]>([]);
  const [dataCheckInType, setDataCheckInType] = useState<ITFDataCheckInType[]>(
    []
  );
  const [lastDateOfMonth, setLastDateOfMonth] = useState<number>(0);
  const dataImageRandomProduct = useSelector(
    randomProductSelector
  ).imgRewardClaim;

  const { showError, setErrorTitle, setErrorMessage, setErrorCode } = useCommon(
    PAGE_KEY.ADMIN_EDIT_CHECK_IN
  );
  const { showSuccess, setSuccessTitle } = useCommonSuccess(
    PAGE_KEY.ADMIN_EDIT_CHECK_IN
  );

  const getDataRewardCheckIn = async () => {
    try {
      const response = await getAllCheckInInit();
      setDataRewardCheckIn(await transform(response.data));
    } catch (error) {}
  };

  const transform = async (data: ITFDataTableRewardCheckIn[]) => {
    const newDataa = await Promise.all(
      data.map(async (item: ITFDataTableRewardCheckIn, count: number) => {
        if (item.checkinType === "CI01" || item.checkinType === "CI03") {
          return {
            key: count,
            descrLocal: item.descrLocal,
            checkinType: item.checkinType,
            product: item.reward,
            dateOfMonth: item.dateOfMonth,
            lastUpdateDate: showFormatDate(item.lastUpdateDate),
            updateBy: item.updateBy,
            imageBase64: item.imageBase64,
            imageType: "image/jpeg",
            imageName: "images",
          };
        } else if (item.checkinType === "CI02") {
          try {
            const response = await getRewardClaim(item.reward);
            return {
              key: count,
              descrLocal: item.descrLocal,
              checkinType: item.checkinType,
              product: {
                productName: response.data[0].title,
                imgProduct: response.data[0].imageBase64,
                productId: response.data[0].productId,
              },
              dateOfMonth: item.dateOfMonth,
              lastUpdateDate: showFormatDate(item.lastUpdateDate),
              updateBy: item.updateBy,
            };
          } catch (error) {
            return {
              key: count,
              descrLocal: item.descrLocal,
              checkinType: item.checkinType,
              product: {
                productName: "Not Found",
                imgProduct: "",
                productId: "Not Found",
              },
              dateOfMonth: item.dateOfMonth,
              lastUpdateDate: showFormatDate(item.lastUpdateDate),
              updateBy: item.updateBy,
            };
          }
        }
      })
    );
    return newDataa;
  };

  const getDataCheckInType = async () => {
    try {
      const response = await getRewardCheckIn();
      setDataCheckInType(response.data);
    } catch (error) {}
  };

  const onFinishRewardCheckIn = async (value: any) => {
    dispatch(setLoading(true));
    const filtered = dataCheckInType.filter((item) => {
      return item.gdCode === value.type;
    });
    let dataOnFinish = {};
    if (value.type === "CI01" || value.type === "CI03") {
      dataOnFinish = {
        dateOfMonth: value.date,
        checkinType: value.type,
        descrLocal: filtered[0].descrLocal,
        reward: value.point,
        isUpdateImage: true,
        imageBase64: dataImageRandomProduct.image,
        imageType: dataImageRandomProduct.imageType,
        imageName: dataImageRandomProduct.imageName,
      };
    } else if (value.type === "CI02") {
      dataOnFinish = {
        dateOfMonth: value.date,
        checkinType: value.type,
        descrLocal: filtered[0].descrLocal,
        reward: dataSelectedRewardProduuct,
      };
    }
    try {
      await putCheckInInit(dataOnFinish);
      dispatch(setImgRewardClaim({}));
      await getDataRewardCheckIn();
      setSuccessTitle(t("Edit Check In Reward Success"));
      showSuccess();
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitle(t("Check In Reward Error"));
      setErrorCode(error.response.status);
      setErrorMessage(message);
      showError();
    } finally {
      onCloseDrawer();
      dispatch(setLoading(false));
    }
  };

  const getDataGetLastDayOfMonth = async () => {
    try {
      const response = await getLastDayOfMonth();
      setLastDateOfMonth(response.data);
    } catch (error) {}
  };

  return {
    getDataRewardCheckIn,
    dataRewardCheckIn,
    getDataCheckInType,
    dataCheckInType,
    onFinishRewardCheckIn,
    getDataGetLastDayOfMonth,
    lastDateOfMonth,
  };
};
