/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Image, Modal, Row, Tabs, Typography } from "antd";
import { useTranslation } from "react-i18next";
import colorConstants from "constant/colorConstants";
import { useRewardCheckIn } from "hooks/rewardCheckIn/useRewardCheckIn";
import { useHistory } from "hooks/history/useHistory";
import { useEffect } from "react";
import { commonSelector } from "store/slice/common";
import { useSelector } from "react-redux";
import type { TabsProps } from "antd";
import { historySelector } from "store/slice/history";
import { isShowFeatureCheckIn } from "constant/common";

const { Text } = Typography;

interface ITFPropsModalHistoryReward {
  isOpen: boolean;
  onClose: Function;
}

const ModalHistoryReward = (props: ITFPropsModalHistoryReward) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const userData = useSelector(commonSelector).dataUser;
  const userId = userData?.userId;
  const { getDataCheckInType, dataCheckInType } = useRewardCheckIn(() => {},
  "");

  const dataTableHistoryCheckInReward =
    useSelector(historySelector).dataTableHistoryCheckInReward;
  const dataTableHistoryClaimInReward =
    useSelector(historySelector).dataTableHistoryClaimInReward;
  const dataTableHistoryRandomInReward =
    useSelector(historySelector).dataTableHistoryRandomInReward;

  const {
    getDataHistoryCheckInReward,
    getDataHistoryRandomReward,
    getDataHistoryClaimReward,
    getDataStatusDescrHistory,
  } = useHistory(dataCheckInType, userId);

  useEffect(() => {
    if (isOpen) {
      if (dataCheckInType.length > 0) {
        getDataHistoryCheckInReward();
      }
      getDataHistoryRandomReward();
      getDataHistoryClaimReward();
    }
  }, [isOpen]);

  useEffect(() => {
    getDataCheckInType();
    getDataStatusDescrHistory();
  }, []);

  const showReward = (
    name: string,
    status: string,
    srcImage: any,
    createDate: any
  ) => {
    return (
      <Card style={{ marginBottom: "10px" }}>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Row>
            <Text>{name} : </Text>
            <Text
              style={{ marginLeft: "8px", color: colorConstants.Primary500 }}
            >
              {status}
            </Text>
          </Row>
          <div>
            {t("Create Reward")} : {createDate}
          </div>
          {srcImage ? (
            <Image
              width={150}
              height={100}
              src={`data:image/jpeg;base64,${srcImage}`}
            />
          ) : (
            <></>
          )}
        </Col>
      </Card>
    );
  };

  const handleShowEmptyDataHistory = (data: Array<any>) => {
    if (data.length > 0) {
      return (
        <>
          {data.map((item) => {
            return (
              <>
                {showReward(
                  item.rewardName,
                  item.statusDescrLocal,
                  item.rewardImg,
                  item.createDate
                )}
              </>
            );
          })}
        </>
      );
    }
    return (
      <Card
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {t("No Data")}
      </Card>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("All"),
      children: (
        <>
          {t("Random Reward")}
          {handleShowEmptyDataHistory(dataTableHistoryRandomInReward)}
          {t("Claim Reward")}
          {handleShowEmptyDataHistory(dataTableHistoryClaimInReward)}
          {isShowFeatureCheckIn && (
            <>
              {t("Daily check In")}
              {handleShowEmptyDataHistory(dataTableHistoryCheckInReward)}
            </>
          )}
        </>
      ),
    },
    {
      key: "2",
      label: t("Random Reward"),
      children: (
        <>{handleShowEmptyDataHistory(dataTableHistoryRandomInReward)}</>
      ),
    },
    {
      key: "3",
      label: t("Claim Reward"),
      children: (
        <>{handleShowEmptyDataHistory(dataTableHistoryClaimInReward)}</>
      ),
    },
    ...(isShowFeatureCheckIn
      ? [
          {
            key: "4",
            label: t("Daily check In"),
            children: (
              <>{handleShowEmptyDataHistory(dataTableHistoryCheckInReward)}</>
            ),
          },
        ]
      : []),
  ];

  return (
    <Modal
      open={isOpen}
      onCancel={() => onClose()}
      footer={false}
      maskClosable={false}
      destroyOnClose
      centered
      title={t("Receiving History")}
      bodyStyle={
        window.innerWidth < 550
          ? { overflowY: "auto", maxHeight: "calc(100vh - 200px)" }
          : {
              height: 600,
              overflowY: "auto",
              maxHeight: "calc(100vh - 200px)",
            }
      }
    >
      <div style={{ marginTop: "20px" }}>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </Modal>
  );
};
export default ModalHistoryReward;
