import {
  addError,
  clearErrorDetail,
  commonSelector,
  removeError,
  setErrorCode as setErrorCodeReducer,
  setErrorTitle as setErrorTitleReducer,
  setErrorMessage as setErrorMessageReducer,
} from "store/slice/common";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";

export const useErrorCommon = (componentName: string) => {
  const dispatch = useAppDispatch();
  const errors = useSelector(commonSelector).errors;
  const errorDetail = useSelector(commonSelector).errorDetail;
  const isShowError: boolean = !!errors.find(
    (key: string) => key === componentName
  );

  const showError = () => {
    dispatch(addError(componentName));
  };

  const hideError = () => {
    dispatch(removeError(componentName));
    dispatch(clearErrorDetail());
  };

  const setErrorCode = (value: string) => {
    dispatch(setErrorCodeReducer(value));
  };
  const setErrorTitle = (value: string) => {
    dispatch(setErrorTitleReducer(value));
  };
  const setErrorMessage = (value: string) => {
    dispatch(setErrorMessageReducer(value));
  };

  return {
    errorDetail,
    isShowError,
    showError,
    hideError,
    setErrorCode,
    setErrorTitle,
    setErrorMessage,
  };
};
