import { getSerialNumberAllUser } from "api/user/user";
import { useAppDispatch } from "store/store";
import { useTranslation } from "react-i18next";
import { setLoading } from "store/slice/common";
import { ITFDataTableSerialNumber } from "types/serialNumberManagement";
import { showFormatDate } from "helper/formatter";
import { useState } from "react";

export const useSerialNumberManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dataSerialNumber, setDataSerialNumber] = useState([]);

  const title = t("Serial Number");

  const getDataSerialNumberAllUser = async () => {
    try {
      dispatch(setLoading(true));
      const response = await getSerialNumberAllUser();
      const data = response.data.map(
        (item: ITFDataTableSerialNumber, count: number) => {
          return {
            ...item,
            key: count,
            createDate: showFormatDate(item.createDate),
            status: item.status === "Y" ? t("used") : t("not used"),
          };
        }
      );
      setDataSerialNumber(data);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  return { title, getDataSerialNumberAllUser, dataSerialNumber };
};
