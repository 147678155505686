import { ITFResponseRewardClaim } from "types/rewardClaim.types";
import { Image, Modal, Row, Typography } from "antd";
import { useRewardClaim } from "hooks/rewardClaim/useRewardClaim";
import { useTranslation } from "react-i18next";
import colorConstants from "constant/colorConstants";
const { Title } = Typography;

interface ITFProps {
  open: boolean;
  onCancel: Function;
  itemSelected: ITFResponseRewardClaim | null;
}

const ModalRewardClaim = (props: ITFProps) => {
  const { open, onCancel, itemSelected } = props;
  const { t } = useTranslation();
  const { onOKCustomerClaimReward } = useRewardClaim(() => onCancel());

  return (
    <Modal
      open={open}
      onCancel={() => onCancel()}
      onOk={() => onOKCustomerClaimReward(itemSelected)}
      okText={t("Confirm")}
      cancelText={t("Cancel")}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Row style={{ alignItems: "baseline" }}>
          <Title level={3} style={{ marginRight: "8px", textAlign: "center" }}>
            {t("Do you want to claim ?")}
          </Title>

          <Title
            level={3}
            style={{ color: colorConstants.Primary500, textAlign: "center" }}
          >
            {itemSelected?.title}
          </Title>
        </Row>

        <div>
          <Image
            width={200}
            src={`data:image/jpeg;base64,${itemSelected?.imageBase64}`}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalRewardClaim;
