import HeaderManagement from "component/headerManagement";
import { Form } from "antd";
import { useAdminManagement } from "hooks/management/useAdminManagement";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import { sorterString } from "helper/sorter";
import { useUser } from "hooks/user/useUser";
import TableManagement from "component/tableManagement";
import { useEffect } from "react";
import DrawerUser from "common/drawer";
import { DisplayDrawerAdmin } from "./displayDrawer";
import { EditOutlined } from "@ant-design/icons";
import { ITFDataTableAdmin } from "types/adminManagement.types";
import { PAGE_KEY, TYPE_ROLE } from "constant";
import ModalError from "common/modal/modalError";
import { useCommon, useCommonSuccess } from "hooks/common/useCommon";
import ModalSuccess from "common/modal/modalSuccess";
import { SearchTable } from "component/searchTable";

const AdminPage = () => {
  const { t } = useTranslation();
  const [formAdminManagement] = Form.useForm();
  const { getColumnSearchProps } = SearchTable();

  const {
    title,
    onOpenDrawer,
    onCloseDrawer,
    isOperDrawer,
    dataEdit,
    handleSetFieldsEditUser,
    onClickEdit,
  } = useAdminManagement(formAdminManagement);
  const { dataUserManagement, onFinishUser, getDataUser } = useUser(
    TYPE_ROLE.ADMIN,
    () => onCloseDrawer()
  );
  const { isShowError, errorDetail, hideError } = useCommon(
    PAGE_KEY.ADMIN_ADD_USER
  );
  const { isShowSuccess, successDetail, hideSuccess } = useCommonSuccess(
    PAGE_KEY.ADMIN_ADD_USER
  );

  const columns: ColumnsType<ITFDataTableAdmin> = [
    {
      title: `${t("First name")} - ${t("Last name")}`,
      key: "name",
      ...getColumnSearchProps("firstName"),
      render: (record: ITFDataTableAdmin) => {
        return (
          <div>
            {record.firstName} {record.lastName}
          </div>
        );
      },
      sorter: (a: ITFDataTableAdmin, b: ITFDataTableAdmin) =>
        sorterString(a.firstName, b.firstName),
      width: 200,
      ellipsis: true,
    },
    {
      title: t("Username"),
      key: "userId",
      dataIndex: "userId",
      ...getColumnSearchProps("userId"),
      sorter: (a: ITFDataTableAdmin, b: ITFDataTableAdmin) =>
        sorterString(a.userId, b.userId),
      width: 150,
    },
    {
      title: t("Status"),
      key: "valiStatus",
      dataIndex: "valiStatus",
      sorter: (a: ITFDataTableAdmin, b: ITFDataTableAdmin) =>
        sorterString(a.valiStatus, b.valiStatus),
      width: 150,
      ...getColumnSearchProps("valiStatus"),
      render: (text: string) => {
        return <div>{text === "A" ? t("Active") : t("Un Active")}</div>;
      },
    },
    {
      title: t("Action"),
      key: "action",
      align: "center",
      render: (record: ITFDataTableAdmin) => {
        return (
          <div>
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => onClickEdit(record)}
            />
          </div>
        );
      },
      width: 100,
      fixed: "right",
    },
  ];

  useEffect(() => {
    getDataUser();
  }, [getDataUser]);

  return (
    <>
      <HeaderManagement title={title} onClickButtonAddNew={onOpenDrawer} />

      <TableManagement columns={columns} data={dataUserManagement} />

      <DrawerUser
        onClose={onCloseDrawer}
        isOpen={isOperDrawer}
        title={title}
        dataEdit={dataEdit}
        form={formAdminManagement}
        handleSetFields={handleSetFieldsEditUser}
        displayContent={<DisplayDrawerAdmin dataEdit={dataEdit} />}
        onFinish={onFinishUser}
      />

      <ModalError
        isOpen={isShowError}
        onCancel={() => hideError()}
        code={errorDetail.code}
        title={errorDetail.title}
        message={errorDetail.message}
      ></ModalError>

      <ModalSuccess
        isOpen={isShowSuccess}
        onCancel={() => hideSuccess()}
        title={successDetail.title}
        message={successDetail.message}
      ></ModalSuccess>
    </>
  );
};

export default AdminPage;
