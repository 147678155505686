// const ENV = process.env;

export const LOGOUFA = "/images/logoUFA.png";
export const Pointer = "/images/pointer.png";
export const Poster1 = "images/poster/poster1.jpg";
export const Poster2 = "images/poster/poster2.jpg";
export const Poster3 = "images/poster/poster3.jpg";
export const Poster4 = "images/poster/poster4.jpg";
export const Poster5 = "images/poster/poster5.jpg";
export const Poster6 = "images/poster/poster6.jpg";
export const Poster7 = "images/poster/poster7.jpg";

export const TYPE_ROLE = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  STAFF: "STAFF",
  USER: "USER",
};

export const PAGE_KEY = {
  LOGIN: "Login",
  CUS_RANDOM_PRODUCT: "CUS_RANDOM_PRODUCT",
  CUS_CLAIM_PRODUCT: "CUS_CLAIM_PRODUCT",
  CUS_REGISTER: "CUS_REGISTER",
  ADMIN_ADD_USER: "ADMIN_ADD_USER",
  ADMIN_ADD_PRODUCT: "ADMIN_ADD_PRODUCT",
  ADMIN_ADD_PRODUCT_RANDOM: "ADMIN_ADD_PRODUCT_RANDOM",
  ADMIN_EDIT_POINT_PRODUCT_RANDOM: "ADMIN_EDIT_POINT_PRODUCT_RANDOM",
  CUS_CHECK_IN: "CUS_CHECK_IN",
  ADMIN_EDIT_CHECK_IN: "ADMIN_EDIT_CHECK_IN",
  ADMIN_EDIT_STATUS_HISTORY: "ADMIN_EDIT_STATUS_HISTORY",
};
