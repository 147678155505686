import { httpClient } from "utils/HttpClient";

export const getRewardCheckIn = async () => {
  return await httpClient.get("/CheckinType");
};

export const getAllCheckInInit = async () => {
  return await httpClient.get("/GetAllCheckinInit");
};

export const putCheckInInit = async (data: any) => {
  return await httpClient.put("/UpdateCheckinInit", data);
};

export const getLastDayOfMonth = async () => {
  return await httpClient.get("/GetLastDayOfMonth");
};
