import { NumericFormat } from "react-number-format";

type NumericProps = {
  id?: string;
  name?: string;
  onChange?: Function | any;
  onBlur?: Function | any;
  disable?: boolean;
  value?: string | number;
  max?: string | number;
  min?: string | number;
  isAllowed?: Function | any;
  placeholder?: string;
  warning?: boolean;
  negativeValue?: boolean;
  allowNegative?: boolean;
  style?: any;
};

export const Numeric = (props: NumericProps) => {
  const { disable = false, warning = false } = props;
  return (
    <NumericFormat
      className={`ant-input`}
      id={props.id}
      name={props.name}
      thousandSeparator={true}
      thousandsGroupStyle="thousand"
      decimalScale={2}
      fixedDecimalScale
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={disable}
      value={props.value}
      max={props.max}
      min={props.min}
      autoComplete="off"
      isAllowed={props.isAllowed}
      placeholder={props.placeholder}
      allowNegative={props.allowNegative}
      style={{
        textAlign: "right",
        borderColor: warning ? "red" : "",
        ...props.style,
      }}
    />
  );
};
