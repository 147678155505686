import { httpClient } from "utils/HttpClient";

export const getProductRandom = async () => {
  return await httpClient.get("/GetProductRandom", {});
};

export const postProductRandomManagement = async (data: any) => {
  return await httpClient.post("/SaveProductRandom", data);
};

export const putProductRandomManagement = async (data: any) => {
  return await httpClient.put("/UpdateProductRandom", data);
};

export const postUserRandomedProduct = async (data: any) => {
  return await httpClient.post("/UserRandomProduct", data);
};

export const deleteRandomedProduct = async (
  updateBy: string,
  productId: string
) => {
  return await httpClient.delete("/CancelProduct", {
    params: { updateBy, productId },
  });
};

export const putRandomPoint = async (data: number) => {
  return await httpClient.put(`/UpdateRandomPoint?point=${data}`);
};
