import { useErrorCommon } from "./useErrorCommon";
import { useSuccessCommon } from "./useSuccessCommon";

export const useCommon = (key: string) => {
  return { ...useErrorCommon(key) };
};

export const useCommonSuccess = (key: string) => {
  return { ...useSuccessCommon(key) };
};
