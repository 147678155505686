import { ITFDataTableUser } from "@/types/userManagement.types";
import { httpClient } from "utils/HttpClient";

export const getUserInfo = async (role: string) => {
  return await httpClient.get("/GetUserInfo", {
    params: { role: role },
  });
};

export const getUserInfoByUserId = async (userId: string) => {
  return await httpClient.get("/GetUserInfo", { params: { userId: userId } });
};

export const putUserInfo = async (data: ITFDataTableUser) => {
  return await httpClient.put("/UpdateUserInfo", data);
};

export const postUserInfo = async (data: ITFDataTableUser) => {
  return await httpClient.post("/InsertUserInfo", data);
};

export const getCheckUserCountProductRandom = async (userId: string) => {
  return await httpClient.get("/CheckUserCountRandom", {
    params: { userId: userId },
  });
};

export const getRandomPoint = async () => {
  return await httpClient.get("/GetRandomPoint");
};

export const importUser = async (data: any) => {
  return await httpClient.post("/ImportUserInfo", data);
};

export const registerUserImport = async (data: any) => {
  return await httpClient.post("/RegisterUserImport", data);
};

export const getFileLogs = async () => {
  return await httpClient.get("/GetFileLog");
};

export const putUserCheckIn = async (data: any) => {
  return await httpClient.put(
    `/UserCheckin?userId=${data.userId}&serialNumber=${data.serialNumber}`
  );
};

export const getSerialNumberAllUser = async () => {
  return await httpClient.get("/GetSerialNumberAllUser");
};
