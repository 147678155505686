import { ITFResponseRewardClaim } from "types/rewardClaim.types";
import { useCallback, useState } from "react";
import { useAppDispatch } from "store/store";
import { setLoading } from "store/slice/common";
import {
  deleteRewardClaim,
  getRewardClaim,
  postRewardClaimManagement,
  putRewardClaimManagement,
  putUserClaimReward,
} from "api/rewardClaim/rewardClaim";
import { useSelector } from "react-redux";
import {
  setImgRewardClaim,
  randomProductSelector,
} from "store/slice/randomProduct";
import { PAGE_KEY } from "constant";
import { get } from "lodash";
import { useUserInfo } from "../user/userUserInfo";
import { useCommon, useCommonSuccess } from "../common/useCommon";
import { parser, showFormatNumber } from "helper/formatter";
import { useTranslation } from "react-i18next";

export const useRewardClaim = (onCloseDrawer: Function) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dataRewardClaim, setDataRewardClaim] = useState<
    ITFResponseRewardClaim[]
  >([]);
  const dataImageRandomProduct = useSelector(
    randomProductSelector
  ).imgRewardClaim;
  const { showError, setErrorTitle, setErrorMessage, setErrorCode } = useCommon(
    PAGE_KEY.CUS_CLAIM_PRODUCT
  );
  const { showSuccess, setSuccessTitle } = useCommonSuccess(
    PAGE_KEY.CUS_CLAIM_PRODUCT
  );
  const { getDataUserInfo } = useUserInfo();
  const [selectedDeleteId, setSelectedDeleteId] = useState<string[]>([]);
  const {
    showError: showErrorAddProduct,
    setErrorTitle: setErrorTitleAddProduct,
    setErrorMessage: setErrorMessageAddProduct,
    setErrorCode: setErrorCodeAddProduct,
  } = useCommon(PAGE_KEY.ADMIN_ADD_PRODUCT);

  const {
    showSuccess: showSuccessAddProduct,
    setSuccessTitle: setSuccessTitleAddProduct,
  } = useCommonSuccess(PAGE_KEY.ADMIN_ADD_PRODUCT);

  const onFinishRewardClaim = async (data: any, dataEdit: any) => {
    const dataPost = [
      {
        title: data.title,
        description: data.description,
        imageBase64: dataImageRandomProduct.image,
        imageType: dataImageRandomProduct.imageType,
        imageName: dataImageRandomProduct.imageName,
        point: Number(parser(data.point)),
        productQTY: Number(parser(data.productQTY)),
      },
    ];

    try {
      if (dataEdit !== undefined) {
        const dataPut = { ...dataPost[0], productId: dataEdit.productId };
        await putRewardClaimManagement(dataPut);
      } else {
        await postRewardClaimManagement(dataPost[0]);
      }
      dispatch(setImgRewardClaim({}));
      getDataRewardClaim();
      onCloseDrawer();
      setSuccessTitleAddProduct(t("Add/Edit Reward Success"));
      showSuccessAddProduct();
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitleAddProduct(t("ADD/EDIT Reward Error"));
      setErrorCodeAddProduct(error.response.status);
      setErrorMessageAddProduct(message);
      showErrorAddProduct();
    } finally {
    }
  };

  const getDataRewardClaim = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const response = await getRewardClaim();
      const dataProduct: ITFResponseRewardClaim[] = response.data.map(
        (item: any, count: number) => {
          return {
            ...item,
            key: `${count}`,
            id: count,
            point: showFormatNumber(item.point),
          };
        }
      );
      setDataRewardClaim(dataProduct);
      setSelectedDeleteId([]);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  const onOKCustomerClaimReward = async (
    itemSelected: ITFResponseRewardClaim | null
  ) => {
    dispatch(setLoading(true));
    if (itemSelected) {
      try {
        await putUserClaimReward({ productId: itemSelected.productId });
        getDataRewardClaim();
        getDataUserInfo();
        setSuccessTitle(t("Redeem Success"));
        showSuccess();
      } catch (error: any) {
        const message = get(error, "response.data");
        setErrorTitle(t("Redeem Error"));
        setErrorCode(error.response.status);
        setErrorMessage(message);
        showError();
      } finally {
        dispatch(setLoading(false));
        onCloseDrawer();
      }
    }
  };

  const rowSelection = {
    onChange: (
      selectedRowsKey: any,
      selectedRows: ITFResponseRewardClaim[]
    ) => {
      setSelectedDeleteId(
        selectedRows.map((item) => {
          return item.productId;
        })
      );
    },
  };

  const onClickDelete = () => {
    dispatch(setLoading(true));
    try {
      selectedDeleteId.map(async (item) => {
        try {
          await deleteRewardClaim(item);
        } catch (error: any) {
          const message = get(error, "response.data");
          setErrorTitleAddProduct(t("Delete Reward Success"));
          setErrorCodeAddProduct(error.response.status);
          setErrorMessageAddProduct(message);
          showErrorAddProduct();
        }
      });
      setTimeout(() => {
        getDataRewardClaim();
      }, 500);
    } catch (error: any) {
      const message = get(error, "response.data");
      setErrorTitleAddProduct(t("Delete Reward Error"));
      setErrorCodeAddProduct(error.response.status);
      setErrorMessageAddProduct(message);
      showErrorAddProduct();
    } finally {
      setSelectedDeleteId([]);
    }
  };

  return {
    dataRewardClaim,
    onFinishRewardClaim,
    getDataRewardClaim,
    onOKCustomerClaimReward,
    rowSelection,
    onClickDelete,
    selectedDeleteId,
  };
};
