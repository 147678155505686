import { Numeric } from "helper/inputNumber";
import { Col, Form, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSetRandomPoint } from "hooks/rewardRandom/useSetRandomPoint";
const { Title } = Typography;

interface ITFProps {
  open: boolean;
  onCancel: Function;
  randomPoint: number;
}

const ModalSetRandomPoint = (props: ITFProps) => {
  const { open, onCancel, randomPoint } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { onFinish } = useSetRandomPoint(() => onCancel());

  useEffect(() => {
    if (open && randomPoint > 0) {
      form.setFieldsValue({
        randomPoint: randomPoint,
      });
    }
  }, [form, open, randomPoint]);

  return (
    <Modal
      open={open}
      onCancel={() => {
        onCancel();
      }}
      onOk={form.submit}
    >
      <Form form={form} onFinish={onFinish}>
        <Col>
          <Title level={3}>{t("Edit Point Product Random")}</Title>

          <Form.Item name="randomPoint">
            <Numeric
              style={{ width: "50%" }}
              isAllowed={(inputObj: any) => {
                const { value } = inputObj;
                return value > 0 && value <= 10000;
              }}
            ></Numeric>
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};

export default ModalSetRandomPoint;
