import { useAppDispatch } from "store/store";
import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { setDataUser } from "store/slice/common";
import { get } from "lodash";
import { getUserInfoByUserId } from "api/user/user";
import { ITFDataTableUser } from "types/userManagement.types";

export const useUserInfo = () => {
  const [cookies] = useCookies(["accessToken", "userInfo"]);
  const dispatch = useAppDispatch();
  

  const getDataUserInfo = useCallback(async () => {
    if (cookies?.userInfo?.nameid) {
      try {
        const responseDataUser = await getUserInfoByUserId(
          cookies?.userInfo?.nameid
        );
        const data: ITFDataTableUser = get(
          responseDataUser,
          "data[0]",
          undefined
        );

        dispatch(setDataUser(data));
      } catch (error) {}
    }
  }, [cookies, dispatch]);

  return { getDataUserInfo };
};
