import {
  GiftOutlined,
  HomeOutlined,
  LaptopOutlined,
  UserOutlined,
  DatabaseOutlined,
  IdcardOutlined,
  BarcodeOutlined,
  HistoryOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { TYPE_ROLE } from "../../../../constant/index";
import { useSelector } from "react-redux";
import { commonSelector } from "store/slice/common";

const { Sider } = Layout;

interface propsITF {
  collapsed: boolean;
}

const SiderLayout = (props: propsITF) => {
  const { t } = useTranslation();
  const { collapsed } = props;
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["selectedTabs", "userInfo"]);
  const userRole = useSelector(commonSelector).dataUser?.role || "";

  const onClickMenu = (e: any) => {
    setCookie("selectedTabs", e.key, { path: "/" });
    history.push(e.key);
  };

  const homeSider = {
    key: "",
    icon: <HomeOutlined />,
    label: t("Home Page"),
  };
  const superAdminSider = {
    key: "super-admin",
    icon: <IdcardOutlined />,
    label: t("Super Admin"),
  };
  const adminSider = {
    key: "admin",
    icon: <IdcardOutlined />,
    label: t("Admin"),
  };
  const staffSider = {
    key: "staff",
    icon: <IdcardOutlined />,
    label: t("Staff"),
  };
  const userSider = {
    key: "user",
    icon: <UserOutlined />,
    label: t("User"),
  };
  const randomRewardSider = {
    key: "reward-random",
    icon: <LaptopOutlined />,
    label: t("Reward Random"),
  };
  const rewardSider = {
    key: "reward",
    icon: <GiftOutlined />,
    label: t("Reward"),
  };
  const dataSider = {
    key: "data",
    icon: <DatabaseOutlined />,
    label: t("Import Data"),
  };
  const checkInRewardSider = {
    key: "check-in",
    icon: <CalendarOutlined />,
    label: t("Check In Reward"),
  };
  const serialNumberSider = {
    key: "serial-number",
    icon: <BarcodeOutlined />,
    label: t("Serial Number"),
  };
  const historySider = {
    key: "history",
    icon: <HistoryOutlined />,
    label: t("History"),
  };

  const menuSuperAdmin = [
    homeSider,
    superAdminSider,
    adminSider,
    staffSider,
    userSider,
    randomRewardSider,
    rewardSider,
    dataSider,
    checkInRewardSider,
    serialNumberSider,
    historySider,
  ];

  const menuAdmin = [
    homeSider,
    staffSider,
    userSider,
    randomRewardSider,
    rewardSider,
    dataSider,
    checkInRewardSider,
    serialNumberSider,
    historySider,
  ];

  const menuStaff = [
    homeSider,
    userSider,
    rewardSider,
    dataSider,
    checkInRewardSider,
    serialNumberSider,
    historySider,
  ];

  const handleShowMenu = () => {
    if (userRole === TYPE_ROLE.SUPER_ADMIN) {
      return menuSuperAdmin;
    } else if (userRole === TYPE_ROLE.ADMIN) {
      return menuAdmin;
    }
    return menuStaff;
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={cookies.selectedTabs}
        onClick={onClickMenu}
        items={handleShowMenu()}
      />
    </Sider>
  );
};

export default SiderLayout;
