import { Col, Row } from "antd";
import { Container } from "./styles";
import { Header } from "antd/es/layout/layout";
import ShowLogo from "./showLogo";
import ShowHeaderMenu from "./showMenu";
import ShowUserInfoHeader from "./showUserInfoHeader";
import ShowHeaderMobile from "./showHeaderMobile";

const HeaderCustomer2 = () => {
  return (
    <Container>
      <Header>
        <Col xs={24} sm={24} md={6} lg={4} xl={4}>
          <ShowLogo></ShowLogo>
        </Col>

        <Col xs={0} sm={0} md={14} lg={14} xl={16}>
          <ShowHeaderMenu></ShowHeaderMenu>
        </Col>

        <Col
          xs={0}
          sm={0}
          md={4}
          lg={6}
          xl={4}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ShowUserInfoHeader></ShowUserInfoHeader>
        </Col>
      </Header>

      <Header className="Header2">
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ShowHeaderMobile></ShowHeaderMobile>
        </Row>
      </Header>
    </Container>
  );
};

export default HeaderCustomer2;
