import { setImgRandomProduct } from "store/slice/randomProduct";
import { useAppDispatch } from "store/store";
import { FormInstance } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ITFDataTableRandomReward } from "types/rewardRandom.types";

export const useRandomRewardManagement = (form: FormInstance) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const title = t("Reward Random");
  const [isOperDrawer, setIsOperDrawer] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<
    ITFDataTableRandomReward | undefined
  >(undefined);

  const onOpenDrawer = () => {
    setIsOperDrawer(true);
  };

  const onCloseDrawer = () => {
    setIsOperDrawer(false);
    setDataEdit(undefined);
    dispatch(setImgRandomProduct({}));
  };

  const onClickEdit = (record: ITFDataTableRandomReward) => {
    setIsOperDrawer(true);
    setDataEdit(record);
  };

  const handleSetFieldsEditUser = () => {
    form.setFieldsValue({
      title: dataEdit?.title,
      percent: dataEdit?.percent,
      fontColor: dataEdit?.textColor,
      backgroundColor: dataEdit?.bgColor,
      fontSize: dataEdit?.frontSize,
    });
  };

  return {
    title,
    onOpenDrawer,
    onCloseDrawer,
    isOperDrawer,
    dataEdit,
    handleSetFieldsEditUser,
    onClickEdit,
  };
};
