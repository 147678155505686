import { ITFPostLogin } from "types/authencation.types";
import { Button, Col, Form, Input, Modal, Select, Typography } from "antd";
import { useAuthencation } from "hooks/authencation";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ModalFirstLogin from "./modalFirstLogin";
import ModalError from "common/modal/modalError";
import { useCommon } from "hooks/common/useCommon";
import { PAGE_KEY } from "constant";
const { Link } = Typography;
interface ITFProps {
  isOpen: boolean;
  onClose: Function;
}

const ModalLogin = (props: ITFProps) => {
  const { isOpen, onClose } = props;
  const { t, i18n } = useTranslation();
  const [isShowModalFirstLogin, setIsShowModalFirstLogin] =
    useState<boolean>(false);
  const { isShowError, errorDetail, hideError } = useCommon(
    PAGE_KEY.CUS_REGISTER
  );

  const { postDataLogin } = useAuthencation();

  const onFinish = async (values: ITFPostLogin) => {
    postDataLogin(values, onClose());
  };

  const onChangeSelectTranslations = (e: string) => {
    i18n.changeLanguage(e);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => onClose()}
      footer={false}
      maskClosable={false}
      destroyOnClose
    >
      <Form onFinish={onFinish} layout="vertical" style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Col span={24}>
            <Form.Item
              label={t("Username")}
              name="userId"
              rules={[
                { required: true, message: t("Please enter") + t("Username") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={t("Password")}
              name="password"
              rules={[
                { required: true, message: t("Please enter") + t("Username") },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Select
            style={{ width: "100%", display: "none" }}
            onChange={onChangeSelectTranslations}
            options={[
              { value: "en", label: "english" },
              { value: "th", label: "thai" },
            ]}
          ></Select>

          <Col span={24} style={{ textAlign: "center" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "50%" }}>
                {t("Log In")}
              </Button>
            </Form.Item>
          </Col>

          <Col span={24} style={{ textAlign: "end", display: "none" }}>
            <Link onClick={() => setIsShowModalFirstLogin(true)}>
              first login
            </Link>
          </Col>
        </Col>
      </Form>

      <ModalFirstLogin
        open={isShowModalFirstLogin}
        onClose={() => setIsShowModalFirstLogin(false)}
      />

      <ModalError
        isOpen={isShowError}
        onCancel={() => hideError()}
        code={errorDetail.code}
        title={errorDetail.title}
        message={errorDetail.message}
      ></ModalError>
    </Modal>
  );
};

export default ModalLogin;
