import colorConstants from "constant/colorConstants";
import styled from "styled-components";

export const Container = styled.div`
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  background: ${colorConstants.Primary500};
  height: 100%;
`;
