/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Numeric } from "helper/inputNumber";
import { useRewardCheckIn } from "hooks/rewardCheckIn/useRewardCheckIn";
import ShowReward from "./showReward";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useAppDispatch } from "store/store";
import { setImgRewardClaim } from "store/slice/randomProduct";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";

interface ITFDisplayDrawerCheckInReward {
  dataEdit?: any;
  setDataRewardProduct: React.Dispatch<React.SetStateAction<string>>;
  isOpen: boolean;
  form: FormInstance;
}

export const DisplayDrawerCheckInReward = (
  props: ITFDisplayDrawerCheckInReward
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { dataEdit, setDataRewardProduct, isOpen, form } = props;

  const [typeCheckIn, setTypeCheckIn] = useState<string>("");

  const { getDataCheckInType, dataCheckInType } = useRewardCheckIn(() => {},
  "");

  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    getDataCheckInType();
  }, []);

  useEffect(() => {
    if (dataEdit) {
      setTypeCheckIn(dataEdit.checkinType);
    }
  }, [dataEdit]);

  useEffect(() => {
    if (isOpen) {
      if (dataEdit) {
        setFileList([
          {
            uid: "-1",
            name: dataEdit.imageName,
            status: "done",
            url: `data:image/jpeg;base64,${dataEdit.imageBase64}`,
          },
        ]);
        dispatch(
          setImgRewardClaim({
            image: dataEdit.imageBase64,
            imageType: dataEdit.imageType,
            imageName: dataEdit.imageName,
          })
        );
      } else {
        setFileList([]);
      }
    }
  }, [form, dataEdit, dispatch, isOpen]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList[0]?.originFileObj !== undefined) {
      dispatch(
        setImgRewardClaim({
          image: (
            await getBase64(newFileList[0].originFileObj as RcFile)
          ).split(",")[1],
          imageType: newFileList[0].originFileObj?.type,
          imageName: newFileList[0].originFileObj?.name,
        })
      );
    } else {
      dispatch(setImgRewardClaim({}));
    }

    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      {fileList.length > 0 ? <CloseCircleOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {fileList.length > 0 ? "" : t("Upload")}
      </div>
    </div>
  );

  const handleShowTypeCheckIn = () => {
    if (typeCheckIn === "CI01" || typeCheckIn === "CI03") {
      return (
        <div>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Input style={{ display: "none" }}></Input>
              <Form.Item
                label={t("Point")}
                name="point"
                rules={[
                  {
                    required: true,
                    message: t("Please enter") + t("Point"),
                  },
                ]}
              >
                <Numeric
                  isAllowed={(inputObj: any) => {
                    const { value } = inputObj;
                    return value >= 0 && value <= 10000;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item label={t("Image")} name="image">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  beforeUpload={() => false}
                  onChange={handleChange}
                  onPreview={handlePreview}
                  openFileDialogOnClick={fileList.length > 0 ? false : true}
                  fileList={fileList}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>

              <Modal
                open={previewOpen}
                footer={null}
                onCancel={() => setPreviewOpen(false)}
                title={previewTitle}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Col>
          </Row>
        </div>
      );
    } else if (typeCheckIn === "CI02") {
      return (
        <div>
          <ShowReward
            selectedItem={
              dataEdit?.checkinType === "CI02"
                ? dataEdit.product.productId
                : undefined
            }
            setDataRewardProduct={setDataRewardProduct}
          ></ShowReward>
        </div>
      );
    }
  };

  return (
    <>
      <Row gutter={10}>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Form.Item
            style={{ display: "none" }}
            label={t("Date")}
            name="date"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Date"),
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={[
                { value: 3, label: "3" },
                { value: 7, label: "7" },
                { value: 14, label: "14" },
                { value: 30, label: "28/30" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Type")}
            name="type"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Type"),
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              onChange={(code: string) => setTypeCheckIn(code)}
              options={dataCheckInType
                .filter((item) => {
                  return item.gdCode.includes("CI0");
                })
                .map((item) => ({
                  label: item.descrLocal,
                  value: item.gdCode,
                }))}
            />
          </Form.Item>
        </Col>
      </Row>
      {handleShowTypeCheckIn()}
    </>
  );
};
