import { Button, Col, Divider, Form, Input, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import { Numeric } from "helper/inputNumber";
import { useEffect, useState } from "react";
import { TYPE_ROLE } from "constant";
import { useUser } from "hooks/user/useUser";
import { useSelector } from "react-redux";
import { commonSelector } from "store/slice/common";

import { useUserInfo } from "hooks/user/userUserInfo";

interface ITFProps {
  isOpen: boolean;
  onClose: Function;
}

const ModalLogin = (props: ITFProps) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const [formEditProfile] = Form.useForm();
  const [isEditProfile, setIsEditProfile] = useState<boolean>(false);
  const { getDataUserInfo } = useUserInfo();

  const { onFinishUser } = useUser(TYPE_ROLE.USER, () => {
    setIsEditProfile(false);
    getDataUserInfo();
    onClose();
  });
  const userData = useSelector(commonSelector).dataUser;

  useEffect(() => {
    if (isOpen) {
      setIsEditProfile(false);
      formEditProfile.setFieldsValue({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        userId: userData?.userId,
        password: null,
        confirmPassword: null,
        address: userData?.address,
        lineId: userData?.lineId,
        phoneNumber: userData?.phoneNumber,
        phoneNumberSecond: userData?.phoneNumberSecond,
        totalDeposit: userData?.totalDeposit,
        depositAmount: userData?.depositAmount,
        totalPoint: userData?.totalPoint,
      });
    }
  }, [formEditProfile, isOpen, userData]);

  return (
    <Modal
      open={isOpen}
      onCancel={() => onClose()}
      footer={false}
      maskClosable={false}
      destroyOnClose
    >
      <Form
        layout="vertical"
        disabled={!isEditProfile}
        onFinish={(value: any) => onFinishUser(value, true)}
        form={formEditProfile}
        style={{ marginTop: "20px" }}
      >
        <Row gutter={10}>
          <Col xs={16} sm={16} md={16} lg={16} xl={16}>
            <Form.Item label={t("Username")} name="userId">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label={t("Total Deposit")} name="totalDeposit">
              <Numeric disable={true} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label={t("Deposit Amount")} name="depositAmount">
              <Numeric disable={true} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label={t("Total Point")} name="totalPoint">
              <Numeric disable={true} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={t("First name")} name="firstName">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={t("Last name")} name="lastName">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name="password"
              label={t("Password")}
              rules={[
                {
                  required: false,
                  message: "Please input your password!",
                },
                { min: 5, message: "min is 5" },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              name="confirmPassword"
              label={t("Confirm Password")}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: false,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Col span={24}>
          <Form.Item label={t("Address")} name="address">
            <TextArea />
          </Form.Item>
        </Col>

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={t("Phone Number")} name="phoneNumber">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label={t("Phone Number Second")}
              name="phoneNumberSecond"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={12}>
            <Form.Item label={`${t("Line ID")} (${t("User")})`} name="lineId">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row style={{ justifyContent: "flex-end", gap: "8px" }}>
        {isEditProfile ? (
          <>
            <Button
              onClick={() => {
                onClose();
                setIsEditProfile(false);
              }}
              danger
            >
              {t("Cancel")}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                formEditProfile.submit();
              }}
            >
              {t("Submit")}
            </Button>
          </>
        ) : (
          <Button type="primary" onClick={() => setIsEditProfile(true)}>
            {t("Edit Profile")}
          </Button>
        )}
      </Row>
    </Modal>
  );
};
export default ModalLogin;
