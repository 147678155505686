/* eslint-disable react-hooks/exhaustive-deps */
import { SearchTable } from "component/searchTable";
import { ITFResponseRewardClaim } from "types/rewardClaim.types";
import type { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { sorterString } from "helper/sorter";
import { Image, Table } from "antd";
import { useEffect, useState } from "react";
import { useRewardClaim } from "hooks/rewardClaim/useRewardClaim";

interface ITFShowReward {
  selectedItem: string | undefined;
  setDataRewardProduct: React.Dispatch<React.SetStateAction<string>>;
}

const ShowReward = (props: ITFShowReward) => {
  const { selectedItem, setDataRewardProduct } = props;
  const { t } = useTranslation();

  const { getColumnSearchProps } = SearchTable();
  const { dataRewardClaim, getDataRewardClaim } = useRewardClaim(() => {});
  const [
    dataRewardClaimWiteDefaultChecked,
    setDataRewardClaimWiteDefaultChecked,
  ] = useState<any[]>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<React.Key[]>([]);

  useEffect(() => {
    getDataRewardClaim();
  }, []);

  useEffect(() => {
    setSelectedRowKey([]);
    const newData = dataRewardClaim.map((item, count: number) => {
      if (selectedItem === item.productId) {
        setSelectedRowKey([count]);
        setDataRewardProduct(item.productId);
      }
      return { ...item, key: count };
    });
    setDataRewardClaimWiteDefaultChecked(newData);
  }, [dataRewardClaim, selectedItem]);

  const columns: ColumnsType<ITFResponseRewardClaim> = [
    {
      title: `${t("Title Product Claim Reward")}`,
      key: "title",
      dataIndex: "title",
      ...getColumnSearchProps("title"),
      sorter: (a: ITFResponseRewardClaim, b: ITFResponseRewardClaim) =>
        sorterString(a.title, b.title),
      width: 100,
      ellipsis: true,
    },
    {
      title: t("Description"),
      key: "description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      sorter: (a: ITFResponseRewardClaim, b: ITFResponseRewardClaim) =>
        sorterString(a.description, b.description),
      width: 100,
    },
    {
      title: t("Image"),
      key: "image",
      width: 200,
      render: (record: ITFResponseRewardClaim) => {
        return (
          <div>
            <Image
              width={200}
              src={`data:image/jpeg;base64,${record.imageBase64}`}
            />
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: ITFResponseRewardClaim[]
    ) => {
      setDataRewardProduct(selectedRows[0].productId);
      setSelectedRowKey(selectedRowKeys);
    },
  };

  return (
    <div>
      <Table
        key={"1"}
        columns={columns}
        rowSelection={{
          type: "radio",
          ...rowSelection,
          selectedRowKeys: selectedRowKey,
        }}
        dataSource={dataRewardClaimWiteDefaultChecked}
        scroll={{ x: 100, y: 500 }}
        pagination={{
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </div>
  );
};
export default ShowReward;
