import { Button, Modal } from "antd";
import { Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;
interface ITFPropsModalError {
  isOpen: boolean;
  onCancel: Function;
  code: string | null;
  title: string | null;
  message: string | null;
}

const ModalError = (props: ITFPropsModalError) => {
  const { isOpen, onCancel, code, title, message } = props;
  const { t } = useTranslation();

  var today = new Date(),
    time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  return (
    <Modal open={isOpen} onCancel={() => onCancel()} footer={false}>
      <Text>
        {date} {time}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <ExclamationCircleOutlined style={{ fontSize: "50px", color: "red" }} />
        <Title type="danger" style={{ textAlign: "center" }}>
          {title} ({code})
        </Title>
        {message ?? <Title level={4}>{message}</Title>}
        <Button danger style={{ marginTop: "20px" }} onClick={() => onCancel()}>
          {t("Close")}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalError;
