import { useRewardClaim } from "hooks/rewardClaim/useRewardClaim";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Image, Row, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ITFResponseRewardClaim } from "types/rewardClaim.types";
import { commonSelector } from "store/slice/common";
import { useSelector } from "react-redux";
import ModalError from "common/modal/modalError";
import ModalRewardClaim from "./modal";
import { useCommon, useCommonSuccess } from "hooks/common/useCommon";
import { PAGE_KEY } from "constant";
import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import ModalCheckIn from "common/modal/modalCheckIn";
import ModalSuccess from "common/modal/modalSuccess";
import { useCookies } from "react-cookie";
import { isShowFeatureCheckIn } from "constant/common";

const { Meta } = Card;
const { Paragraph } = Typography;
const App: React.FC = () => {
  const { t } = useTranslation();
  const { getDataRewardClaim, dataRewardClaim } = useRewardClaim(() => {});
  const isLoading = useSelector(commonSelector).loading;
  const [isShowModalConfirm, setIsShowModalConfirm] = useState<boolean>(false);
  const [itemSelected, setitemSelected] =
    useState<ITFResponseRewardClaim | null>(null);

  const { isShowError, errorDetail, hideError } = useCommon(
    PAGE_KEY.CUS_CLAIM_PRODUCT
  );
  const { isShowSuccess, successDetail, hideSuccess } = useCommonSuccess(
    PAGE_KEY.CUS_CLAIM_PRODUCT
  );

  const userPoint = useSelector(commonSelector).dataUser?.totalPoint || 0;
  const [cookies] = useCookies(["accessToken"]);
  const isHaveToken = cookies.accessToken ? true : false;

  useEffect(() => {
    getDataRewardClaim();
  }, [getDataRewardClaim]);

  return (
    <>
      <Container>
        <Row>
          {dataRewardClaim?.map(
            (item: ITFResponseRewardClaim, count: number) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={8}
                  key={count}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Card
                    style={{
                      width: 300,
                      marginTop: 16,
                    }}
                    actions={[
                      <div
                        style={{
                          cursor:
                            userPoint !== 0 && userPoint >= item.point
                              ? "pointer"
                              : "no-drop",
                        }}
                        onClick={() => {
                          if (userPoint !== 0 && userPoint >= item.point) {
                            setIsShowModalConfirm(true);
                            setitemSelected(item);
                          }
                        }}
                      >
                        <div>
                          {item.point} ({t("Point(s)")})
                        </div>
                        <ShoppingCartOutlined key="setting" />
                      </div>,
                    ]}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Skeleton loading={isLoading}>
                        <Meta
                          title={item.title}
                          description={
                            <div style={{ width: 280 }}>
                              <Paragraph
                                ellipsis={{
                                  rows: 1,
                                  expandable: true,
                                  symbol: "more",
                                }}
                              >
                                {item.description}
                              </Paragraph>
                            </div>
                          }
                        />
                        <Divider />
                        <div>
                          <Image
                            width={100}
                            height={100}
                            src={`data:image/jpeg;base64,${item.imageBase64}`}
                          />
                        </div>
                      </Skeleton>
                    </div>
                  </Card>
                </Col>
              );
            }
          )}
        </Row>
        <ModalRewardClaim
          open={isShowModalConfirm}
          onCancel={() => setIsShowModalConfirm(false)}
          itemSelected={itemSelected}
        />
        <ModalError
          isOpen={isShowError}
          onCancel={() => hideError()}
          code={errorDetail.code}
          title={errorDetail.title}
          message={errorDetail.message}
        ></ModalError>
        <ModalSuccess
          isOpen={isShowSuccess}
          onCancel={() => hideSuccess()}
          title={successDetail.title}
          message={successDetail.message}
        ></ModalSuccess>
        {isHaveToken && isShowFeatureCheckIn ? (
          <ModalCheckIn></ModalCheckIn>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default App;
