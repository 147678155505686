import { Col, Form, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
interface ITFProps {
  dataEdit: any;
}
export const DisplayDrawerSuperAdmin = (props: ITFProps) => {
  const { dataEdit } = props;
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Username")}
            name="userId"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Username"),
              },
            ]}
          >
            <Input disabled={dataEdit === undefined ? false : true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Password")}
            name="password"
            rules={[
              { required: false, message: t("Please enter") + t("Password") },
              { min: 5, message: t("Enter at least 5 characters") + "" },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Status")}
            name="valiStatus"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Status"),
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={[
                { value: "A", label: t("Active") },
                { value: "C", label: t("Un Active") },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
