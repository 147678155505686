import { LOGOUFA } from "constant/index";

const ShowLogo = () => {
  return (
    <div className="logo">
      <img
        src={LOGOUFA}
        alt="Logo"
        style={{
          width: 150,
          height: 50,
        }}
      />
    </div>
  );
};
export default ShowLogo;
