import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ITFDataTableUser } from "types/userManagement.types";
import { ITFDataTableRandomReward } from "types/rewardRandom.types";
import { commonSelector } from "store/slice/common";
import { useSelector } from "react-redux";
import { ITFResponseRewardClaim } from "types/rewardClaim.types";
import { ITFDataTableSerialNumber } from "types/serialNumberManagement";
interface ITFPropsTableManagement {
  columns: ColumnsType<
    ITFDataTableUser | ITFDataTableRandomReward | ITFResponseRewardClaim | any
  >;
  data:
    | ITFDataTableUser[]
    | ITFDataTableRandomReward[]
    | ITFResponseRewardClaim[]
    | ITFDataTableSerialNumber[];
  summryTable?: (data: readonly any[]) => React.ReactNode;
  rowSelection?: any;
  key?: string;
}

const TableManagement = (props: ITFPropsTableManagement) => {
  const {
    columns,
    data,
    summryTable = () => {
      return <></>;
    },
    rowSelection,
    key = "tableRandomProduct",
  } = props;
  const isLoading = useSelector(commonSelector).loading;

  return (
    <>
      <Table
        key={key}
        columns={columns}
        rowSelection={rowSelection}
        dataSource={data}
        scroll={{ x: 1500 }}
        pagination={{
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        summary={(pageData) => summryTable(pageData)}
        loading={isLoading}
      />
    </>
  );
};

export default TableManagement;
