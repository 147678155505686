import { Row } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ShowHeaderMenu = () => {
  const { t } = useTranslation();

  const showMenuBar = () => {
    return (
      <>
        {showMenu(t("Home Page"), "")}
        {showMenu(t("Random Reward"), "random-reward")}
        {showMenu(t("Claim Reward"), "claim-reward")}
      </>
    );
  };

  const showMenu = (label: string, path: string) => {
    return (
      <Link to={path} className="menuBar">
        <div className="titleHeader" style={{ color: "white" }}>
          {label}
        </div>
      </Link>
    );
  };

  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "space-between",
      }}
      className="menuBar"
    >
      {showMenuBar()}
    </Row>
  );
};

export default ShowHeaderMenu;
