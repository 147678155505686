/* eslint-disable react-hooks/exhaustive-deps */
import colorConstants from "constant/colorConstants";
import { Col, Divider, Image, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { commonSelector } from "store/slice/common";
import { useRewardCheckIn } from "hooks/rewardCheckIn/useRewardCheckIn";

const { Text } = Typography;

const ShowRewardModalCheckIn = () => {
  const { t } = useTranslation();

  const dataUser = useSelector(commonSelector).dataUser;

  const { getDataRewardCheckIn, dataRewardCheckIn } = useRewardCheckIn(() => {},
  "");

  const currentUserCheckIn = useMemo(
    () => (dataUser?.checkinCount && dataUser?.checkinCount + 1) || 1,
    [dataUser]
  );

  useEffect(() => {
    getDataRewardCheckIn();
  }, []);

  const handleShowImageReward = (item: any, checkedIn: boolean) => {
    let showCheckedIn = (
      <div>
        {checkedIn ? (
          <Text style={{ color: colorConstants.Primary500 }}>
            ({t("has been awarded")})
          </Text>
        ) : (
          <div style={{ marginTop: "22px" }}></div>
        )}
      </div>
    );
    if (item.checkinType === "CI02") {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {item.product.productName}
          {showCheckedIn}
          <Image
            width={100}
            height={100}
            src={`data:image/jpeg;base64,${item.product.imgProduct}`}
          />
        </div>
      );
    } else if (item.checkinType === "CI01" || item.checkinType === "CI03") {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {item.product} {item.descrLocal}
          {showCheckedIn}
          <Image
            width={100}
            height={100}
            src={`data:image/jpeg;base64,${item.imageBase64}`}
          />
        </div>
      );
    }
  };

  const showReward = (day: string, checkedIn: boolean, item: any) => {
    return (
      <Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.key}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text>
            {t("Day")} {day}
          </Text>

          {handleShowImageReward(item, checkedIn)}
        </div>

        <Divider></Divider>
      </Col>
    );
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Row>
        {dataRewardCheckIn.map((item) => {
          return (
            <>
              {showReward(
                item.dateOfMonth,
                currentUserCheckIn > item.dateOfMonth,
                item
              )}
            </>
          );
        })}
      </Row>
    </div>
  );
};
export default ShowRewardModalCheckIn;
