import ModalCheckIn from "common/modal/modalCheckIn";
import { Container } from "./styles";
import { Button, Carousel, Col, Row } from "antd";
import { WhatsAppOutlined, DribbbleOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import {
  Poster1,
  Poster2,
  Poster3,
  Poster4,
  Poster5,
  Poster6,
  Poster7,
} from "constant/index";
import { isShowFeatureCheckIn } from "constant/common";

const contentStyle: React.CSSProperties = {
  color: "#fff",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
};

const App: React.FC = () => {
  const { t } = useTranslation();
  const [cookies] = useCookies(["accessToken"]);
  const isHaveToken = cookies.accessToken ? true : false;

  const showImage = (srcImg: any) => {
    return (
      <img
        src={srcImg}
        alt="Logo"
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    );
  };
  return (
    <>
      <Container>
        <Row justify={"center"}>
          <Col xs={20} sm={16} md={14} lg={10} xl={8}>
            <Carousel autoplay>
              <div>
                <h3 style={contentStyle}>{showImage(Poster1)}</h3>
              </div>
              <div>
                <h3 style={contentStyle}>{showImage(Poster2)}</h3>
              </div>
              <div>
                <h3 style={contentStyle}>{showImage(Poster3)}</h3>
              </div>
              <div>
                <h3 style={contentStyle}>{showImage(Poster4)}</h3>
              </div>
            </Carousel>
          </Col>
        </Row>

        <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
          <Button
            type="primary"
            icon={<DribbbleOutlined />}
            size="large"
            href="https://me-qr.com/5T1k9456"
            target="_blank"
          >
            {t("Play game")}
          </Button>
          <Button
            icon={<WhatsAppOutlined style={{ color: "#fff" }} />}
            style={{ background: "#00B900", color: "#fff" }}
            size="large"
            href="https://me-qr.com/WWPtvYTN"
            target="_blank"
          >
            {t("Contact admin")}
          </Button>
        </div>

        <Row justify={"center"}>
          <Col xs={20} sm={16} md={14} lg={10} xl={8}>
            <Carousel autoplay>
              <div>
                <h3 style={contentStyle}>{showImage(Poster5)}</h3>
              </div>
              <div>
                <h3 style={contentStyle}>{showImage(Poster6)}</h3>
              </div>
              <div>
                <h3 style={contentStyle}>{showImage(Poster7)}</h3>
              </div>
            </Carousel>
          </Col>
        </Row>

        {isHaveToken && isShowFeatureCheckIn ? <ModalCheckIn></ModalCheckIn> : <></>}
      </Container>
    </>
  );
};

export default App;
