import { useAppDispatch } from "store/store";
import { Col, Form, FormInstance, Input, Modal, Row, Upload } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { setImgRewardClaim } from "store/slice/randomProduct";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Numeric } from "helper/inputNumber";
import { ITFResponseRewardClaim } from "types/rewardClaim.types";

interface ITFProps {
  form: FormInstance;
  dataEdit?: ITFResponseRewardClaim;
  isOpen: boolean;
}

export const DisplayDrawer = (props: ITFProps) => {
  const { form, dataEdit, isOpen } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (isOpen) {
      if (dataEdit) {
        setFileList([
          {
            uid: "-1",
            name: dataEdit.imageName,
            status: "done",
            url: `data:image/jpeg;base64,${dataEdit.imageBase64}`,
          },
        ]);
        dispatch(
          setImgRewardClaim({
            image: dataEdit.imageBase64,
            imageType: dataEdit.imageType,
            imageName: dataEdit.imageName,
          })
        );
      } else {
        setFileList([]);
      }
    }
  }, [form, dataEdit, dispatch, isOpen]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList[0]?.originFileObj !== undefined) {
      dispatch(
        setImgRewardClaim({
          image: (
            await getBase64(newFileList[0].originFileObj as RcFile)
          ).split(",")[1],
          imageType: newFileList[0].originFileObj?.type,
          imageName: newFileList[0].originFileObj?.name,
        })
      );
    } else {
      dispatch(setImgRewardClaim({}));
    }

    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      {fileList.length > 0 ? <CloseCircleOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {fileList.length > 0 ? "" : t("Upload")}
      </div>
    </div>
  );

  return (
    <>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Title")}
            name="title"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Title"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={t("Description")} name="description">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={t("Image")} name="image">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              beforeUpload={() => false}
              onChange={handleChange}
              onPreview={handlePreview}
              openFileDialogOnClick={fileList.length > 0 ? false : true}
              fileList={fileList}
            >
              {uploadButton}
            </Upload>
          </Form.Item>

          <Modal
            open={previewOpen}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
            title={previewTitle}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("Point Claim Reward")}
            name="point"
            rules={[
              {
                required: true,
                message: t("Please enter") + t("Point Claim Reward"),
              },
            ]}
          >
            <Numeric
              isAllowed={(inputObj: any) => {
                const { value } = inputObj;
                return value >= 0 && value <= 100000;
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* <Row gutter={10}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={t("ProductQTY Claim Reward")}
            name="productQTY"
            rules={[
              {
                required: false,
                message: t("Please enter") + t("ProductQTY Claim Reward"),
              },
            ]}
          >
            <Numeric
              isAllowed={(inputObj: any) => {
                const { value } = inputObj;
                return value >= 0 && value <= 10000;
              }}
            />
          </Form.Item>
        </Col>
      </Row> */}
    </>
  );
};
