import { Button, Row, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

interface ITFHeaderManagement {
  title: string;
  onClickButtonAddNew: (e: React.MouseEvent | React.KeyboardEvent) => void;
  hiddenDelete?: boolean;
  disabledDelete?: boolean;
  onClickDelete?: Function;
  disabledAdd?: boolean;
  wordTooltip?: string;
  hiddenAdd?: boolean;
}

const HeaderManagement = (props: ITFHeaderManagement) => {
  const { t } = useTranslation();
  const {
    title,
    onClickButtonAddNew,
    hiddenDelete = true,
    disabledDelete,
    onClickDelete,
    disabledAdd,
    wordTooltip,
    hiddenAdd = false,
  } = props;

  return (
    <Row
      style={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h1>{title}</h1>
      <Row>
        <div hidden={hiddenDelete}>
          <Button
            type="primary"
            danger
            onClick={() => onClickDelete?.()}
            disabled={disabledDelete}
            style={{ marginRight: "5px" }}
          >
            {t("Delete")} {title}
          </Button>
        </div>

        <div hidden={hiddenAdd}>
          {wordTooltip !== " " ? (
            <Tooltip title={wordTooltip}>
              <Button
                type="primary"
                onClick={onClickButtonAddNew}
                disabled={disabledAdd}
              >
                {t("Add")} {title}
              </Button>
            </Tooltip>
          ) : (
            <Button
              type="primary"
              onClick={onClickButtonAddNew}
              disabled={disabledAdd}
            >
              {t("Add")} {title}
            </Button>
          )}
        </div>
      </Row>
    </Row>
  );
};

export default HeaderManagement;
