/* eslint-disable react-hooks/exhaustive-deps */
import HeaderManagement from "component/headerManagement";
import { Form, Image } from "antd";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import { sorterNumber, sorterString } from "helper/sorter";
import TableManagement from "component/tableManagement";
import { useEffect, useState } from "react";
import DrawerUser from "common/drawer";
// import { DisplayDrawer } from "./displayDrawer";
import { EditOutlined } from "@ant-design/icons";
import { useRewardCheckInManagement } from "hooks/management/useRewardCheckInManagement";
import { PAGE_KEY } from "constant";
import ModalError from "common/modal/modalError";
import { useCommon, useCommonSuccess } from "hooks/common/useCommon";
import ModalSuccess from "common/modal/modalSuccess";
import { SearchTable } from "component/searchTable";
import { ITFDataTableRewardCheckIn } from "@/types/rewardCheckIn.type";
import { DisplayDrawerCheckInReward } from "./displayDrawer";
import { useRewardCheckIn } from "hooks/rewardCheckIn/useRewardCheckIn";

const CheckInRewardPage = () => {
  const { t } = useTranslation();
  const { getColumnSearchProps } = SearchTable();
  const [formRewardCheckInManagement] = Form.useForm();
  const [dataRewardProduct, setDataRewardProduct] = useState<string>("");

  const {
    title,
    onOpenDrawer,
    onCloseDrawer,
    isOperDrawer,
    dataEdit,
    handleSetFieldsEdit,
    onClickEdit,
  } = useRewardCheckInManagement(formRewardCheckInManagement);

  const {
    onFinishRewardCheckIn,
    getDataRewardCheckIn,
    dataRewardCheckIn,
    getDataCheckInType,
  } = useRewardCheckIn(() => onCloseDrawer(), dataRewardProduct);

  const { isShowError, errorDetail, hideError } = useCommon(
    PAGE_KEY.ADMIN_EDIT_CHECK_IN
  );

  const { isShowSuccess, successDetail, hideSuccess } = useCommonSuccess(
    PAGE_KEY.ADMIN_EDIT_CHECK_IN
  );

  useEffect(() => {
    getDataCheckInType();
  }, []);

  const handleShowReward = (item: any) => {
    if (item.checkinType === "CI02") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {item.product.productName}
          <Image
            width={200}
            src={`data:image/jpeg;base64,${item.product.imgProduct}`}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {item.product}
          <Image
            width={200}
            src={`data:image/jpeg;base64,${item.imageBase64}`}
          />
        </div>
      );
    }
  };

  const columns: ColumnsType<ITFDataTableRewardCheckIn> = [
    {
      title: t("Date of Reward"),
      key: "dateOfMonth",
      dataIndex: "dateOfMonth",
      ...getColumnSearchProps("dateOfMonth"),
      sorter: (a: ITFDataTableRewardCheckIn, b: ITFDataTableRewardCheckIn) =>
        sorterNumber(a.dateOfMonth, b.dateOfMonth),
      width: 150,
    },
    {
      title: `${t("Type")}`,
      key: "descrLocal",
      dataIndex: "descrLocal",
      ...getColumnSearchProps("descrLocal"),
      sorter: (a: ITFDataTableRewardCheckIn, b: ITFDataTableRewardCheckIn) =>
        sorterString(a.descrLocal, b.descrLocal),
      width: 200,
      ellipsis: true,
    },
    {
      title: `${t("Product / Point")}`,
      key: "checkinType",
      width: 200,
      ellipsis: true,
      render: (_: string, record: ITFDataTableRewardCheckIn) => {
        return handleShowReward(record);
      },
      align: "center",
    },
    {
      title: t("Last Update Date"),
      key: "lastUpdateDate",
      dataIndex: "lastUpdateDate",
      ...getColumnSearchProps("lastUpdateDate"),
      sorter: (a: ITFDataTableRewardCheckIn, b: ITFDataTableRewardCheckIn) =>
        sorterString(a.lastUpdateDate, b.lastUpdateDate),
      width: 150,
    },
    {
      title: t("Last Update By"),
      key: "updateBy",
      dataIndex: "updateBy",
      ...getColumnSearchProps("updateBy"),
      sorter: (a: ITFDataTableRewardCheckIn, b: ITFDataTableRewardCheckIn) =>
        sorterString(a.updateBy, b.updateBy),
      width: 150,
    },
    {
      title: t("Action"),
      key: "action",
      align: "center",
      render: (record: ITFDataTableRewardCheckIn) => {
        return (
          <div>
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => onClickEdit(record)}
            />
          </div>
        );
      },
      width: 100,
      fixed: "right",
    },
  ];

  useEffect(() => {
    getDataRewardCheckIn();
  }, []);

  return (
    <>
      <HeaderManagement
        title={title}
        onClickButtonAddNew={onOpenDrawer}
        disabledDelete={true}
        hiddenDelete={true}
        hiddenAdd={true}
      />

      <TableManagement columns={columns} data={dataRewardCheckIn} />

      <DrawerUser
        onClose={onCloseDrawer}
        isOpen={isOperDrawer}
        title={title}
        dataEdit={dataEdit}
        form={formRewardCheckInManagement}
        handleSetFields={handleSetFieldsEdit}
        displayContent={
          <DisplayDrawerCheckInReward
            dataEdit={dataEdit}
            setDataRewardProduct={setDataRewardProduct}
            isOpen={isOperDrawer}
            form={formRewardCheckInManagement}
          />
        }
        onFinish={onFinishRewardCheckIn}
        width={800}
      />

      <ModalError
        isOpen={isShowError}
        onCancel={() => hideError()}
        code={errorDetail.code}
        title={errorDetail.title}
        message={errorDetail.message}
      ></ModalError>

      <ModalSuccess
        isOpen={isShowSuccess}
        onCancel={() => hideSuccess()}
        title={successDetail.title}
        message={successDetail.message}
      ></ModalSuccess>
    </>
  );
};
export default CheckInRewardPage;
