import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ITFDataTableUser } from "types/userManagement.types";

export interface SuccessDetailType {
  code: string | null;
  title: string | null;
  message: string | null;
}

export interface commonStage {
  loadingLogin: boolean;
  dataUser: ITFDataTableUser | undefined;
  loading: boolean;
  success: string[];
  successDetail: SuccessDetailType;
}

const initialValues: commonStage = {
  loadingLogin: false,
  dataUser: undefined,
  loading: false,
  success: [],
  successDetail: {
    code: "",
    title: "",
    message: "",
  },
};

const commonSlice = createSlice({
  name: "commonSuccess",
  initialState: initialValues,
  reducers: {
    setLoadingLogin: (state: commonStage, action: PayloadAction<boolean>) => {
      state.loadingLogin = action.payload;
    },
    setDataUser: (
      state: commonStage,
      action: PayloadAction<ITFDataTableUser | undefined>
    ) => {
      state.dataUser = action.payload;
    },
    setLoading: (state: commonStage, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addSuccess: (state: commonStage, action: PayloadAction<string>) => {
      state.success.push(action.payload);
    },
    removeSuccess: (state: commonStage, action: PayloadAction<string>) => {
      state.success = state.success.filter(
        (loading: string) => action.payload !== loading
      );
    },
    setSuccessCode: (state: commonStage, action: PayloadAction<string>) => {
      state.successDetail.code = action.payload;
    },
    setSuccessTitle: (state: commonStage, action: PayloadAction<string>) => {
      state.successDetail.title = action.payload;
    },
    setSuccessMessage: (state: commonStage, action: PayloadAction<string>) => {
      state.successDetail.message = action.payload;
    },
    clearSuccessDetail: (state: commonStage) => {
      state.successDetail = initialValues.successDetail;
    },
  },
});

export const {
  setLoadingLogin,
  setDataUser,
  setLoading,
  addSuccess,
  removeSuccess,
  setSuccessCode,
  setSuccessTitle,
  setSuccessMessage,
  clearSuccessDetail,
} = commonSlice.actions;
export const commonSuccessSelector = (store: RootState) => store.commonSuccess;

export default commonSlice.reducer;
