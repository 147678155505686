/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Image, Select, Table, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import type { TabsProps } from "antd";
import { ITFDataTableHistory } from "types/historyManagement.types";
import type { ColumnsType } from "antd/es/table";
import { sorterString } from "helper/sorter";
import { SearchTable } from "component/searchTable";
import { useHistory } from "hooks/history/useHistory";
import { useEffect, useState } from "react";
import { commonSelector } from "store/slice/common";
import { useSelector } from "react-redux";
import { useRewardCheckIn } from "hooks/rewardCheckIn/useRewardCheckIn";
import {
  updateStatueCheckinHistory,
  updateStatusUserProductReward,
  updateUserProductRandom,
} from "api/history/history";
import ModalSuccess from "common/modal/modalSuccess";
import { useCommonSuccess } from "hooks/common/useCommon";
import { PAGE_KEY } from "constant";
import AdvanceSearch from "./advanceSearch";
import { historySelector } from "store/slice/history";
import { isShowFeatureCheckIn } from "constant/common";

const HistoryRewardPage = () => {
  const { t } = useTranslation();
  const { getColumnSearchProps } = SearchTable();
  const isLoading = useSelector(commonSelector).loading;
  const dataTableHistoryCheckInReward =
    useSelector(historySelector).dataTableHistoryCheckInReward;
  const dataTableHistoryClaimInReward =
    useSelector(historySelector).dataTableHistoryClaimInReward;
  const dataTableHistoryRandomInReward =
    useSelector(historySelector).dataTableHistoryRandomInReward;

  const [seletedTabs, setSeletedTabs] = useState<string>("random");

  const [form] = Form.useForm();

  const { isShowSuccess, successDetail, hideSuccess } = useCommonSuccess(
    PAGE_KEY.ADMIN_EDIT_STATUS_HISTORY
  );
  const { showSuccess, setSuccessTitle } = useCommonSuccess(
    PAGE_KEY.ADMIN_EDIT_STATUS_HISTORY
  );

  const { getDataCheckInType, dataCheckInType } = useRewardCheckIn(() => {},
  "");

  const {
    getDataHistoryCheckInReward,
    getDataHistoryRandomReward,
    getDataHistoryClaimReward,
    getDataStatusDescrHistory,
    dataStatusHistory,
  } = useHistory(dataCheckInType);

  const onChangeStatus = async (
    id: string,
    productId: string,
    status: string,
    response: any
  ) => {
    if (id === "dataHistoryRandomReward") {
      try {
        await updateUserProductRandom(
          productId,
          status,
          response.extNumber,
          response.userId || ""
        );

        setSuccessTitle(t("Change status success"));
        showSuccess();
      } catch (error) {
      } finally {
        getDataHistoryRandomReward();
      }
    } else if (id === "dataHistoryClaimReward") {
      try {
        await updateStatusUserProductReward(
          productId,
          status,
          response.extNumber,
          response.userId || ""
        );
        setSuccessTitle(t("Change status success"));
        showSuccess();
      } catch (error) {
      } finally {
        getDataHistoryClaimReward();
      }
    } else if (id === "dataHistoryCheckInReward") {
      try {
        await updateStatueCheckinHistory(
          productId,
          status,
          response.userId || "" || ""
        );
        setSuccessTitle(t("Change status success"));
        showSuccess();
      } catch (error) {
      } finally {
        getDataHistoryCheckInReward();
      }
    }
  };

  const columns: ColumnsType<ITFDataTableHistory> = [
    {
      title: t("Name"),
      key: "userId",
      dataIndex: "userId",
      sorter: (a: ITFDataTableHistory, b: ITFDataTableHistory) =>
        sorterString(a.userId, b.userId),
      width: 150,
      ...getColumnSearchProps("userId"),
    },
    {
      title: t("Product name"),
      key: "rewardName",
      dataIndex: "rewardName",
      sorter: (a: ITFDataTableHistory, b: ITFDataTableHistory) =>
        sorterString(a.rewardName, b.rewardName),
      width: 150,
      ...getColumnSearchProps("userId"),
    },
    {
      title: t("Image"),
      key: "rewardImg",
      width: 150,
      render: (record: ITFDataTableHistory) => {
        if (record.checkinType === "CI02" || record.checkinType === "empty") {
          return (
            <div>
              <Image
                width={200}
                src={`data:image/jpeg;base64,${record.rewardImg}`}
              />
            </div>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: t("Last Update Date"),
      key: "updateDate",
      dataIndex: "updateDate",
      ...getColumnSearchProps("updateDate"),
      sorter: (a: ITFDataTableHistory, b: ITFDataTableHistory) =>
        sorterString(a.updateDate, b.updateDate),
      width: 150,
    },
    {
      title: t("Last Update By"),
      key: "updateBy",
      dataIndex: "updateBy",
      ...getColumnSearchProps("updateBy"),
      sorter: (a: ITFDataTableHistory, b: ITFDataTableHistory) =>
        sorterString(a.updateBy, b.updateBy),
      width: 150,
    },
    {
      title: t("Status"),
      key: "status",
      dataIndex: "status",
      render: (text: string, record: ITFDataTableHistory) => {
        return (
          <div>
            <Form.Item name={`${record.id}_${record.key}`}>
              <Select
                style={{ width: "100%" }}
                onChange={(status: string) =>
                  onChangeStatus(
                    record.id,
                    record.id === "dataHistoryCheckInReward"
                      ? record.response.serialNo
                      : record.response.productId,
                    status,
                    record.response
                  )
                }
                options={dataStatusHistory.map((item) => ({
                  label: item.descrLocal,
                  value: item.gdCode,
                }))}
              />
            </Form.Item>
          </div>
        );
      },
      width: 150,
    },
  ];

  const showContentTabs = (dataTable: ITFDataTableHistory[]) => {
    return (
      <>
        <AdvanceSearch
          dataStatusHistory={dataStatusHistory}
          seletedTabs={seletedTabs}
          disableAdvanceSearch={dataTable.length <= 0}
        ></AdvanceSearch>
        <Form form={form}>
          <Table
            key={dataTable.length}
            columns={columns}
            dataSource={dataTable}
            scroll={{ x: 1500 }}
            pagination={{
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            loading={isLoading}
          />
        </Form>
      </>
    );
  };

  useEffect(() => {
    if (dataCheckInType.length > 0 && dataStatusHistory.length > 0) {
      getDataHistoryCheckInReward();
      getDataHistoryRandomReward();
      getDataHistoryClaimReward();
    }
  }, [dataCheckInType, dataStatusHistory]);

  useEffect(() => {
    getDataCheckInType();
    getDataStatusDescrHistory();
  }, []);

  useEffect(() => {
    if (dataTableHistoryCheckInReward.length !== 0) {
      dataTableHistoryCheckInReward.forEach((item) => {
        form.setFieldsValue({
          [`${item.id}_${item.key}`]: item.status,
        });
      });
    }
  }, [dataTableHistoryCheckInReward]);

  useEffect(() => {
    if (dataTableHistoryClaimInReward.length !== 0) {
      dataTableHistoryClaimInReward.forEach((item) => {
        form.setFieldsValue({
          [`${item.id}_${item.key}`]: item.status,
        });
      });
    }
  }, [dataTableHistoryClaimInReward]);

  useEffect(() => {
    if (dataTableHistoryRandomInReward.length !== 0) {
      dataTableHistoryRandomInReward.forEach((item) => {
        form.setFieldsValue({
          [`${item.id}_${item.key}`]: item.status,
        });
      });
    }
  }, [dataTableHistoryRandomInReward]);

  const items: TabsProps["items"] = [
    {
      key: "random",
      label: t("History Reward Random"),
      children: showContentTabs(dataTableHistoryRandomInReward),
    },
    {
      key: "claim",
      label: t("History Reward Claim"),
      children: showContentTabs(dataTableHistoryClaimInReward),
    },
    ...(isShowFeatureCheckIn
      ? [
          {
            key: "checkIn",
            label: t("History Reward Daily Check In"),
            children: showContentTabs(dataTableHistoryCheckInReward),
          },
        ]
      : []),
  ];

  return (
    <div>
      <h1>{t("History User")}</h1>
      <Tabs
        defaultActiveKey="random"
        items={items}
        onChange={(value) => setSeletedTabs(value)}
      />

      <ModalSuccess
        isOpen={isShowSuccess}
        onCancel={() => hideSuccess()}
        title={successDetail.title}
        message={successDetail.message}
      ></ModalSuccess>
    </div>
  );
};
export default HistoryRewardPage;
