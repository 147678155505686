import { Button, Modal, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import colorConstants from "constant/colorConstants";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

interface ITFPropsModalError {
  isOpen: boolean;
  onCancel: Function;
  title: string | null;
  message: string | null;
}

const ModalSuccess = (props: ITFPropsModalError) => {
  const { isOpen, onCancel, title, message } = props;
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onCancel={() => onCancel()} footer={false}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <CheckCircleOutlined
          style={{ fontSize: "50px", color: colorConstants.Primary500 }}
        />
        <Title
          style={{ color: colorConstants.Primary500, textAlign: "center" }}
        >
          {title}
        </Title>
        {message ?? <Title level={4}>{message}</Title>}
        <Button
          type="primary"
          style={{ marginTop: "20px" }}
          onClick={() => onCancel()}
        >
          {t("Close")}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalSuccess;
