import { postLogin } from "api/common/authentication";
import { ITFPostLogin, ITFResponsePostLogin } from "types/authencation.types";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useAppDispatch } from "store/store";
import {
  setDataUser,
  setLoadingLogin,
  setShowModalCheckIn,
} from "store/slice/common";
import { useSelector } from "react-redux";
import { commonSelector } from "store/slice/common";
import { PAGE_KEY, TYPE_ROLE } from "constant";
import { ITFDataTableUser } from "types/userManagement.types";
import { get } from "lodash";
import { getUserInfoByUserId } from "api/user/user";
import { useCommon } from "./common/useCommon";
import { useTranslation } from "react-i18next";

export const useAuthencation = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const userRole = useSelector(commonSelector).dataUser?.role || "";

  const { showError, setErrorTitle, setErrorMessage, setErrorCode } = useCommon(
    PAGE_KEY.LOGIN
  );

  const [, setCookie] = useCookies([
    "accessToken",
    "selectedTabs",
    "refreshToken",
    "userInfo",
    "userRole",
  ]);

  const postDataLogin = async (values: ITFPostLogin, onClose?: () => void) => {
    dispatch(setLoadingLogin(true));
    try {
      const response = await postLogin({
        userId: values.userId,
        password: values.password,
        lang: values?.lang,
      });
      const responsePostLogin: ITFResponsePostLogin = response.data;

      setCookie("selectedTabs", "home", { path: "/" });
      setCookie("accessToken", responsePostLogin.accessToken, { path: "/" });
      setCookie("refreshToken", responsePostLogin.refreshToken, { path: "/" });
      setCookie("userRole", responsePostLogin.role, { path: "/" });
      setCookie("userInfo", jwtDecode(responsePostLogin.accessToken), {
        path: "/",
      });
      const responseDataUser = await getUserInfoByUserId(values.userId);
      const data: ITFDataTableUser = get(
        responseDataUser,
        "data[0]",
        undefined
      );
      dispatch(setDataUser(data));

      if (userRole === TYPE_ROLE.USER) {
        history.push("");
      } else {
        history.push("");
      }

      onClose?.();
      dispatch(setShowModalCheckIn(true));
    } catch (error: any) {
      const message = get(error, "response.data.message");
      setErrorTitle(t("Login Error"));
      setErrorCode(error?.response?.status);
      setErrorMessage(message);
      showError();
    } finally {
      dispatch(setLoadingLogin(false));
    }
  };

  return { postDataLogin };
};
