import {
  addSuccess,
  clearSuccessDetail,
  commonSuccessSelector,
  removeSuccess,
  setSuccessTitle as setSuccessTitleReducer,
} from "store/slice/commonSuccess";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";

export const useSuccessCommon = (componentName: string) => {
  const dispatch = useAppDispatch();
  const success = useSelector(commonSuccessSelector).success;
  const successDetail = useSelector(commonSuccessSelector).successDetail;
  const isShowSuccess: boolean = !!success.find(
    (key: string) => key === componentName
  );

  const showSuccess = () => {
    dispatch(addSuccess(componentName));
  };

  const hideSuccess = () => {
    dispatch(removeSuccess(componentName));
    dispatch(clearSuccessDetail());
  };

  const setSuccessTitle = (value: string) => {
    dispatch(setSuccessTitleReducer(value));
  };

  return {
    successDetail,
    isShowSuccess,
    showSuccess,
    hideSuccess,
    setSuccessTitle,
  };
};
