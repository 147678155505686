import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ITFImgRandomProduct } from "types/rewardRandom.types";

export interface commonStage {
  imgRandomProduct: ITFImgRandomProduct;
  imgRewardClaim: ITFImgRandomProduct;
}

const initialValues: commonStage = {
  imgRandomProduct: {
    image: "",
    imageName: "",
    imageType: "",
  },
  imgRewardClaim: {
    image: "",
    imageName: "",
    imageType: "",
  },
};

const randomProductSlice = createSlice({
  name: "randomProduct",
  initialState: initialValues,
  reducers: {
    setImgRandomProduct: (
      state: commonStage,
      action: PayloadAction<ITFImgRandomProduct>
    ) => {
      state.imgRandomProduct = action.payload;
    },
    setImgRewardClaim: (
      state: commonStage,
      action: PayloadAction<ITFImgRandomProduct>
    ) => {
      state.imgRewardClaim = action.payload;
    },
  },
});

export const { setImgRandomProduct, setImgRewardClaim } =
  randomProductSlice.actions;
export const randomProductSelector = (store: RootState) => store.ramdomProduct;

export default randomProductSlice.reducer;
