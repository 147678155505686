/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Divider, Modal, Table, Tooltip, Typography } from "antd";
import {
  CheckCircleOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import { useSelector } from "react-redux";
import { commonSelector, setShowModalCheckIn } from "store/slice/common";
import { useAppDispatch } from "store/store";
import colorConstants from "constant/colorConstants";
import { useEffect, useMemo, useState } from "react";
import ModalSerialNumber from "../modalSerialNumber";
import { useTranslation } from "react-i18next";
import ShowRewardModalCheckIn from "./showReward";
import { useRewardCheckIn } from "hooks/rewardCheckIn/useRewardCheckIn";

const { Text } = Typography;

const ModalCheckIn = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const open = useSelector(commonSelector).isShowModalCheckIn;
  const dataUser = useSelector(commonSelector).dataUser;
  const [showModalSerialNumber, setShowModalSerialNumber] =
    useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [showModalReward, setShowModalReward] = useState<boolean>(false);

  const { getDataGetLastDayOfMonth, lastDateOfMonth } = useRewardCheckIn(
    () => {},
    ""
  );

  const currentUserCheckIn = useMemo(
    () => (dataUser?.checkinCount && dataUser?.checkinCount + 1) || 1,
    [dataUser]
  );

  const onClickDailyCheckIn = () => {
    setShowModalSerialNumber(true);
  };

  const showFieldDailyCheckIn = (icon: JSX.Element, day: number) => {
    return (
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {icon}
        <div style={{ marginTop: "8px" }}>
          {t("Day")} {day}
        </div>
      </Col>
    );
  };

  const handleShowDailyCheckIn = (number: number) => {
    if (number < currentUserCheckIn) {
      return showFieldDailyCheckIn(
        <CheckCircleOutlined
          style={{ color: "green", fontSize: 30, cursor: "no-drop" }}
        />,
        number
      );
    } else if (number === currentUserCheckIn) {
      return showFieldDailyCheckIn(
        <PlusCircleOutlined
          style={{
            color: colorConstants.Primary500,
            fontSize: 30,
            cursor: "pointer",
          }}
          onClick={() => onClickDailyCheckIn()}
        />,
        number
      );
    } else if (number > currentUserCheckIn) {
      return showFieldDailyCheckIn(
        <MinusCircleOutlined
          style={{
            color: colorConstants.BaseGray,
            fontSize: 30,
            cursor: "no-drop",
          }}
        />,
        number
      );
    }
  };

  const onClose = () => {
    dispatch(setShowModalCheckIn(false));
  };

  const handleDataSource = () => {
    let dayy = 1;
    const columnTable: any = [];
    const sourceTable: any = [];

    for (let indexColumn = 0; indexColumn < 7; indexColumn++) {
      columnTable.push({
        dataIndex: indexColumn,
        key: indexColumn,
        width: 100,
      });
      const sourceByCell = [];
      for (
        let indexCell = 0;
        indexCell <= Number((lastDateOfMonth / 4).toFixed(0));
        indexCell++
      ) {
        if (dayy > lastDateOfMonth) {
          break;
        } else {
          sourceByCell.push(<>{handleShowDailyCheckIn(dayy)}</>);
        }

        dayy = dayy + 1;
      }

      if (sourceByCell.length > 0) {
        sourceTable.push({
          ...sourceByCell,
          key: indexColumn,
        });
      }
    }

    setColumns(columnTable);
    setDataSource(sourceTable);
  };

  useEffect(() => {
    if (lastDateOfMonth > 0) {
      handleDataSource();
    }
  }, [currentUserCheckIn, lastDateOfMonth]);

  useEffect(() => {
    getDataGetLastDayOfMonth();
  }, []);

  return (
    <Modal
      open={open}
      title={t("Daily check in")}
      footer={[
        <Button key="close" onClick={() => onClose()}>
          {t("Close")}
        </Button>,
      ]}
      maskClosable={false}
      onCancel={() => onClose()}
      centered
      bodyStyle={
        window.innerWidth < 550
          ? {}
          : { overflowY: "auto", maxHeight: "calc(100vh - 200px)" }
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <Tooltip
          title={
            <Col>
              <div>
                1.เพียงท่านเติมยอดฝากครั้งแรกของวันรับ Serial Number
                จากแอดมินเพื่อใช้ในการเช็คอินรับของรางวัลตามเงื่อนไขได้เลย
              </div>
              <div>2.การเช็คอินจะถูกรีเซ็ททุกวันที่ 1 ของทุกเดือน</div>
              <div>
                3.การเช็คอินแต่ละครั้งจะต้องมีการทำเทิร์นหนึ่งครั้งก่อน
                ระบบจะตรวจสอบโดยผู้เชียวชาญอีกครั้งก่อนการรับและส่งมอบของรางวัล
              </div>
              <div>4.การตัดสินใจของทีมงานถือเป็นที่สิ้นสุด</div>
            </Col>
          }
          trigger="click"
          placement="bottomLeft"
        >
          <Text
            underline
            style={{
              cursor: "pointer",
              color: colorConstants.Primary500,
              marginBottom: "10px",
            }}
          >
            {t("Conditions for accepting prizes")}
          </Text>
        </Tooltip>

        {window.innerWidth < 550 ? (
          <Text
            underline
            style={{ cursor: "pointer", color: colorConstants.Primary500 }}
            onClick={() => setShowModalReward(true)}
          >
            {t("Prizes each day")}
          </Text>
        ) : (
          <></>
        )}
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={window.innerWidth < 550 ? { y: 400 } : {}}
        showHeader={false}
        pagination={false}
        key={currentUserCheckIn + lastDateOfMonth}
      />

      {window.innerWidth < 550 ? (
        <></>
      ) : (
        <div style={{ marginTop: "10px" }}>
          <Divider orientation="left">
            <Text
              strong
              style={{ fontSize: "20px", color: colorConstants.Primary500 }}
            >
              {t("Reward")}
            </Text>
          </Divider>

          <ShowRewardModalCheckIn></ShowRewardModalCheckIn>
        </div>
      )}

      <ModalSerialNumber
        open={showModalSerialNumber}
        onClose={() => setShowModalSerialNumber(false)}
      ></ModalSerialNumber>

      <Modal
        title={t("Reward")}
        open={showModalReward}
        onCancel={() => setShowModalReward(false)}
        okButtonProps={{ style: { display: "none" } }}
      >
        <ShowRewardModalCheckIn></ShowRewardModalCheckIn>
      </Modal>
    </Modal>
  );
};
export default ModalCheckIn;
